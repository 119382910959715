import {Injectable} from '@angular/core';
import {SearchFilters} from './employee-list.model';
import {MasterEntitiesService} from '../master.entities.service';
import {stringify, parse} from 'zipson';

@Injectable({
  providedIn: 'root'
})
export class EmployeeListFiltersService {

  constructor(private masterEntitiesService: MasterEntitiesService) { }

  static readonly LOCAL_STORAGE_FILTERS_ITEM_NAME = 'rb-employee-list-filters';

  getFilters(suffix): SearchFilters {
    let result = {
      searchQuery: {value: ''},
      location: {value: ''},
      department: {value: ''},
      position: {value: ''},
      jobTitle: {value: ''},
      employeeType: {value: ''},
      employeeStatus: {value: ''},
      hasMismatches: {value: false},
      newHire: {value: false},
      externalSystem: {value: ''},
    } as SearchFilters;
    const cachedFiltersStr = localStorage.getItem(EmployeeListFiltersService.LOCAL_STORAGE_FILTERS_ITEM_NAME + '-' + suffix);
    if (cachedFiltersStr) {
      try {
        result = parse(cachedFiltersStr) as SearchFilters;
      } catch (e) {
        this.loadFiltersOptions(result);
      }
    } else {
      this.loadFiltersOptions(result);
    }
    return result
  }

  saveFilters(filters: SearchFilters, suffix) {
    localStorage.setItem(EmployeeListFiltersService.LOCAL_STORAGE_FILTERS_ITEM_NAME + '-' + suffix, stringify(filters));
  }

  buildSearchParam(filters: SearchFilters) {
    let result = '';
    for (const [key, value] of Object.entries(filters)) {
      result += value.value ? `${key}:${value.value},` : '';
    }
    return result;
  }

  resetFilters(suffix) {
    localStorage.removeItem(EmployeeListFiltersService.LOCAL_STORAGE_FILTERS_ITEM_NAME + '-' + suffix);
  }

  loadFiltersOptions(filters: SearchFilters): void {
    this.loadLocations(filters);
    this.loadPositions(filters);
    this.loadDepartments(filters);
    this.loadJobTitles(filters);
    this.loadEmployeeStatuses(filters);
    this.loadEmployeeTypes(filters);
    this.loadExternalSystems(filters);
  }

  loadLocations(filters: SearchFilters){
    this.masterEntitiesService.getLocations().subscribe(
      response => { filters.location.options = response; },
      error => { console.log(error);});
  }

  loadEmployeeStatuses(filters: SearchFilters){
    this.masterEntitiesService.getEmployeeStatuses().subscribe(
      response => { filters.employeeStatus.options = response; },
      error => { console.log(error);});
  }

  loadEmployeeTypes(filters: SearchFilters){
    this.masterEntitiesService.getEmployeeTypes().subscribe(
      response => { filters.employeeType.options = response; },
      error => { console.log(error); });
  }

  loadJobTitles(filters: SearchFilters){
    this.masterEntitiesService.getJobTitles().subscribe(
      response => { filters.jobTitle.options = response; },
      error => { console.log(error); });
  }

  loadDepartments(filters: SearchFilters){
    this.masterEntitiesService.getDepartments().subscribe(
      response => { filters.department.options = response; },
      error => { console.log(error); });
  }

  loadPositions(filters: SearchFilters){
    this.masterEntitiesService.getPositions().subscribe(
      response => { filters.position.options = response; },
      error => { console.log(error); });
  }

  loadExternalSystems(filters: SearchFilters){
    this.masterEntitiesService.getOriginTypes().subscribe(
      response => { filters.externalSystem.options = response; },
      error => { console.log(error); });
  }
}
