import { DatePipe } from '@angular/common';
import {
  Input,
  Component,
  OnInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output, OnDestroy
} from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import {
  BaseEvent,
  Employment,
  State,
  StateAmountType,
  StateFilingStatus,
  TaxForm
} from '../../master.entities.models';
import {compareById, compareYesNoBoolean} from '../../utils';
import {MasterEntitiesService} from '../../master.entities.service';
import {Observable} from 'rxjs';
import {SpinnerService} from '../../../spinner/spinner.service';
import {MasterEmployeeService} from '../../master.employee.service';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../login/auth.service';
import {DetailsDataService} from '../details.data.service';

@Component({
  selector: 'app-state-tax',
  templateUrl: './state-tax.component.html',
  styleUrls: ['./state-tax.component.css']
})
export class StateTaxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() employment: Employment;
  @Output() stateTaxEventEmitter = new EventEmitter<BaseEvent>();

  taxForms: Observable<TaxForm[]>;
  stateTaxFilingStatuses: Observable<StateFilingStatus[]>;
  stateTaxAmountTypes: Observable<StateAmountType[]>;
  states: Observable<State[]>;
  subscription;

  constructor(public elementRef: ElementRef,
              private route: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private masterEntitiesService: MasterEntitiesService,
              private masterEmployeeService: MasterEmployeeService,
              private spinnerService: SpinnerService,
              private toastrService: ToastrService,
              public authService: AuthenticationService,
              private detailsDataService: DetailsDataService) { }

  ngOnInit() {
    this.subscription = this.detailsDataService.currentMessage.subscribe((msg: any) => {
      if (msg && msg.id && msg.textLabel === 'Tax') {
        this.states = this.masterEntitiesService.getStates();
        this.taxForms = this.masterEntitiesService.getTaxForms();
        this.stateTaxFilingStatuses = this.masterEntitiesService.getStateFilingStatuses();
        this.stateTaxAmountTypes = this.masterEntitiesService.getStateAmountTypes();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public compareFn(item1, item2): boolean {
    return compareById(item1, item2);
  }

  public compareByBool(item1, item2): boolean {
    return compareYesNoBoolean(item1, item2);
  }

  getEmploymentIndex() {
    return this.employment.id;
  }
}
