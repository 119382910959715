<mat-sidenav-container>
  <mat-sidenav #sidenav>
  </mat-sidenav>
  <mat-sidenav-content style="text-align: center">
    <mat-nav-list>
      <a mat-list-item routerLink='/employees/list' routerLinkActive='list-item-active'>
        <mat-icon aria-hidden="false">groups</mat-icon>&nbsp; Employee Directory</a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list style="padding-top: 0px">
      <a mat-list-item routerLink='/employees/unmatched-records' routerLinkActive='list-item-active'>
        <mat-icon aria-hidden="false">report</mat-icon>&nbsp;
        <div>&nbsp;Unmatched Records
          <span *ngIf="unmatchedRecordsAmt > 0" class="badge badge-right-0">{{unmatchedRecordsAmt}}</span>
        </div>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <a mat-list-item routerLink='/employees/virtual-employee' routerLinkActive='list-item-active'>
        <mat-icon aria-hidden="false">account_box</mat-icon>&nbsp; Hospital Rev Accounts</a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <br/>
  </mat-sidenav-content>
</mat-sidenav-container>
