import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { GlobalConstants } from '../global-constants';
import {Observable, of} from 'rxjs';
import {stringify, parse} from 'zipson';
import {
  AahaAccount,
  BenefitClass, Country,
  Department,
  EmployeeStatus, EmployeeType,
  Ethnicity,
  FederalAmountType, FederalFilingStatus,
  Gender, JobTitle, Location,
  MaritalStatus, OvertimeStatus, PayFrequency, PayType, Position, State, StateAmountType, StateFilingStatus,
  TaxForm, YesNo
} from './master.entities.models';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MasterEntitiesService {

  constructor(private http: HttpClient) { }

  private yesno:YesNo[] = [
    {key: 'Y', name: 'Yes', bool: true},
    {key: 'N', name: 'No', bool: false}
  ];

  public getYesNoOptions(): Observable<YesNo[]> {
    return of(this.yesno);
  }

  public getGenders(): Observable<Array<Gender>> {
    return this.getEntities<Gender>('genders');
  }

  public getEthnicities(): Observable<Array<Ethnicity>> {
    return this.getEntities<Ethnicity>('ethnicities');
  }

  public getMaritalStatuses(): Observable<Array<MaritalStatus>> {
    return this.getEntities<MaritalStatus>('marital-statuses');
  }

  public getTaxForms(): Observable<Array<TaxForm>> {
    return this.getEntities<TaxForm>('tax-forms');
  }
  public getFederalAmountTypes(): Observable<Array<FederalAmountType>> {
    return this.getEntities<FederalAmountType>('federal_tax_amount_types');
  }
  public getFederalFilingStatuses(): Observable<Array<FederalFilingStatus>> {
    return this.getEntities<FederalFilingStatus>('federal_tax_filing_statuses');
  }
  public getStateAmountTypes(): Observable<Array<StateAmountType>> {
    return this.getEntities<StateAmountType>('state_tax_amount_types');
  }
  public getStateFilingStatuses(): Observable<Array<StateFilingStatus>> {
    return this.getEntities<StateFilingStatus>('state_tax_filing_statuses');
  }

  public getDepartments(): Observable<Array<Department>> {
    return this.getEntities<Department>('departments');
  }

  public getPositions(): Observable<Array<Position>> {
    return this.getEntities<Position>('positions');
  }

  public getLocations(): Observable<Array<Location>> {
    return this.getEntities<Location>('locations');
  }

  public getJobTitles(): Observable<Array<JobTitle>> {
    return this.getEntities<JobTitle>('jobtitles');
  }

  public getEmployeeStatuses(): Observable<Array<EmployeeStatus>> {
    return this.getEntities<EmployeeStatus>('employee-statuses');
  }

  public getEmployeeTypes(): Observable<Array<EmployeeType>> {
    return this.getEntities<EmployeeType>('employee-types');
  }
  public getBenefitClasses(): Observable<Array<BenefitClass>> {
    return this.getEntities<EmployeeType>('benefit-classes');
  }
  public getOvertimeStatuses(): Observable<Array<OvertimeStatus>> {
    return this.getEntities<OvertimeStatus>('overtime-statuses');
  }
  public getPayTypes(): Observable<Array<PayType>> {
    return this.getEntities<PayType>('pay-types');
  }
  public getPayFrequencies(): Observable<Array<PayFrequency>> {
    return this.getEntities<PayFrequency>('pay-frequencies');
  }

  public getCountries(): Observable<Array<Country>> {
    return this.getEntities<Country>('countries');
  }
  public getStates(): Observable<Array<State>> {
    return this.getEntities<State>('states');
  }
  public getOriginTypes(): Observable<Array<State>> {
    return this.getEntities<State>('origin-types?employeeSyncOnly=true');
  }
  public getAahaAccounts(): Observable<Array<AahaAccount>> {
    return this.getEntities<AahaAccount>('aaha-accounts');
  }

  private getEntities<T>(entityApiPath: string): Observable<Array<T>> {
    const cacheKey = entityApiPath + '_v1';
    const item = localStorage.getItem(cacheKey);
    if(item) {
      const parsed = parse(item) as CachedItem<Array<T>>;
      if(Date.now() - parsed.loadtime > 300000) {
        localStorage.removeItem(cacheKey)
      } else {
        return of(parsed.data);
      }
    }
    return this.http.get<Array<T>>(`${GlobalConstants.apiURL}/api/${entityApiPath}`)
      .pipe(tap(d => localStorage.setItem(cacheKey, stringify({loadtime: Date.now(), data: d }
      ))));
  }
}

interface CachedItem<T> {
  loadtime: number;
  data: T;
}
