import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SortDirection} from '@angular/material/sort';
import {GlobalConstants} from '../global-constants';
import {Observable} from 'rxjs';
import {VetSourceEmployeesPage} from './vetsource-employee.models';

@Injectable({
  providedIn: 'root'
})
export class VetSourceEmployeeService {

  constructor(private http: HttpClient) {
  }

  findAll(page: number, size: number, sort: string, order: SortDirection, search, excludeMatched = false):
    Observable<VetSourceEmployeesPage> {
    const sortParameter = sort ? `&sort=${sort},${order}` : '';
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<VetSourceEmployeesPage>(`${GlobalConstants.apiURL}/api/vetsource-employees?page=${page}&size=${size}&excludeMatched=${excludeMatched}${sortParameter}${searchParameter}`);
  }
}
