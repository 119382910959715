import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit,} from '@angular/core';
import {Subscription} from 'rxjs';
import {SessionTimerService} from '../../services/session-timer.service';
import {AuthenticationService} from '../../../login/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'session-expiration-alert',
  templateUrl: './session-expiration-alert.component.html',
  styleUrls: ['./session-expiration-alert.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpirationAlertComponent implements OnInit, OnDestroy {
  /**
   * Should start the timer or not. Usually, you can set it to true if a user is authenticated.
   */
  @Input() startTimer? = true;

  /**
   * Count down seconds.
   */
  @Input() alertAt? = 60;

  showModal = false;
  expired = false;
  maxInactiveDelay = 1800; // sec
  private sessionTimerSubscription!: Subscription;

  constructor(
    private el: ElementRef,
    public sessionTimer: SessionTimerService,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.sessionTimer.resetTimer();
    this.trackSessionTime();
    // move element to bottom of page (just before </body>)
    // so it can be displayed above everything else
    document.body.appendChild(this.el.nativeElement);
  }

  private trackSessionTime() {
    this.expired = false;
    this.sessionTimerSubscription = this.sessionTimer.remainSeconds$
      .subscribe((t) => {
          this.checkCondition(t);
        }
      );
  }

  private checkCondition(remainSeconds) {
    if (remainSeconds <= 0) {
      this.expire();
    } else if (remainSeconds <= this.alertAt) {
      if (this.getLastInteractionTime() < this.maxInactiveDelay) {
        this.authService.relogin(); // should be always silent
      } else {
        this.openAlert();
      }
    }
  }

  asDate(remainSeconds): Date {
    const result = new Date(0, 0, 0);
    result.setSeconds(remainSeconds);
    return result
  }

  private expire() {
    this.expired = true;
    this.closeAlert();
    this.authService.logout();
  }

  getLastInteractionTime() {
    return (new Date().getTime() - this.sessionTimer.dateOfLastInteraction.getTime()) / 1000;
  }

  continue() {
    this.closeAlert();
    this.sessionTimer.stopTimer();
    this.authService.relogin();
  }

  logout() {
    this.sessionTimer.stopTimer();
    this.closeAlert();
    this.authService.logout();
  }

  openAlert(): void {
    this.showModal = true;
    document.body.classList.add('sea-modal-open');
  }

  closeAlert(): void {
    this.showModal = false;
    document.body.classList.remove('sea-modal-open');
  }

  cleanUp() {
    this.sessionTimer.stopTimer();
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
    }
  }

  reload() {
    this.closeAlert();
    location.reload();
  }

  ngOnDestroy(): void {
    this.el.nativeElement.remove();
    this.cleanUp();
  }
}
