import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalConstants} from '../../global-constants';
import {Observable} from 'rxjs';
import {EmployeesPage} from './employee-list.model';
import {SortDirection} from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class EmployeeListService {

  constructor(private http: HttpClient) {
  }

  getAll(page, size, sort: string, order: SortDirection, search, excludeMatched = false): Observable<EmployeesPage> {
    const sortParameter = sort ? `&sort=${sort},${order}` : '';
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<EmployeesPage>(`${GlobalConstants.apiURL}/api/employees?page=${page}&excludeMatched=${excludeMatched}&size=${size}${sortParameter}${searchParameter}`);
  }

  count(search): Observable<EmployeesPage> {
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<EmployeesPage>(`${GlobalConstants.apiURL}/api/employees/count?${searchParameter}`);
  }
}
