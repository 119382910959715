<div class="fixActionRow">
  <h1 mat-dialog-title>
    <div *ngIf="data.systemCode !== 'NETSUITE' && data.systemCode !== 'VETSOURCE'">
      Matching Details
    </div>
    <div *ngIf="data.systemCode === 'NETSUITE' || data.systemCode === 'VETSOURCE'">
      <span *ngIf="selectedIndex === 0">Confirm record type</span>
      <span *ngIf="selectedIndex === 1 && confirmRecordType === 'EMPLOYEE'">Match to an employee</span>
      <span
        *ngIf="selectedIndex === 1 && confirmRecordType === 'VIRTUAL_EMPLOYEE'">Match to a Hospital Revenue Account</span>
      <span *ngIf="selectedIndex === 1 && confirmRecordType === 'NETSUITE_EMPLOYEE'">Match to a Netsuite employee</span>
      <span
        *ngIf="selectedIndex === 1 && confirmRecordType === 'VETSOURCE_EMPLOYEE'">Match to a VetSource employee</span>
    </div>
  </h1>
  <mat-progress-bar *ngIf="progress"
                    [color]="'primary'"
                    [mode]="'indeterminate'">
  </mat-progress-bar>

  <div mat-dialog-content style="max-height: none">
    <div *ngIf="data.content" class="padding-bottom-15 padding-top-15" [innerHTML]="data.content">
    </div>
    <div *ngIf="data.systemCode === 'NETSUITE' || data.systemCode === 'VETSOURCE'"
         class="padding-bottom-15 padding-top-15">
      <mat-stepper orientation="horizontal" #stepper>
        <mat-step
          *ngIf="data.confirmRecordType !== 'NETSUITE_EMPLOYEE' && data.confirmRecordType !== 'VETSOURCE_EMPLOYEE'">
          <ng-template matStepContent>
            <div style="padding: 15px">
              <mat-radio-group class="tp-radio-group" [(ngModel)]="confirmRecordType">
                <mat-radio-button class="tp-radio-button" [value]="'EMPLOYEE'">
                  Employee
                </mat-radio-button>
                <br/><br/>
                <mat-radio-button class="tp-radio-button" [value]="'VIRTUAL_EMPLOYEE'">
                  Hospital Revenue Account
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-template>
        </mat-step>
        <mat-step>
          <div>
            <form class="form-inline list-employee-search-header">
              <div class="row">
                <div class="col col-lg-8">
                  <mat-form-field class="toast-top-full-width" appearance="standard">
                    <mat-label>Search</mat-label>
                    <input type="text" placeholder="" aria-label="Search" matInput (input)="reset()"
                           [(ngModel)]="data.searchQuery">
                  </mat-form-field>
                </div>
                <div class="col col-lg-4" *ngIf="data.systemCode !== 'VETSOURCE'">
                  <mat-checkbox color="primary" style="padding-top: 30px" (change)="changeIncludeMatched()">
                    Include matched
                  </mat-checkbox>
                </div>
              </div>
            </form>
          </div>
          <div class="table-container">
            <div class="table-loading-shade"
                 *ngIf="isLoadingResults">
              <mat-spinner [diameter]="50" *ngIf="isLoadingResults"></mat-spinner>
            </div>

            <div class="table-box">
              <table mat-table [dataSource]="gridRows"
                     [style]="!isLoadingResults && gridRows && gridRows.length === 0 ? 'visibility:hidden' : ''"
                     matSort matSortActive="id" matSortDisableClear matSortDirection="asc">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef style="width:5% !important;"></th>
                  <td mat-cell *matCellDef="let row" style="width:5% !important;">
                    <mat-radio-button class="tp-radio-button"
                                      [disabled]=row.disabled
                                      (click)="$event.stopPropagation()"
                                      (change)="$event ? select(row) : null"
                                      [checked]="selection.isSelected(row)">
                    </mat-radio-button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Employee Name</th>
                  <td mat-cell *matCellDef="let row">
                    {{row.name}}
                    <mat-icon *ngIf="!row.name" class="font-18">remove</mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef>Location</th>
                  <td mat-cell *matCellDef="let row">
                    {{row.location}}
                    <mat-icon *ngIf="!row.location" class="font-18">remove</mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="mapped">
                  <th mat-header-cell *matHeaderCellDef>Matched</th>
                  <td mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.mapped" class="font-18" color="accent">check</mat-icon>
                    <mat-icon *ngIf="!row.mapped" class="font-18">remove</mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="includeMatched ? displayedColumnsExt : displayedColumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: includeMatched ? displayedColumnsExt : displayedColumns;"
                    (click)="!row.disabled && select(row)"
                    [ngStyle]="row.disabled && {'background-color': 'lightgrey'}"
                    [matTooltip]="row.tooltip"></tr>
              </table>
              <ng-container *ngIf="!isLoadingResults && gridRows && gridRows.length === 0">
                <p class="text-center">There is no data for this filter</p>
              </ng-container>
            </div>
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize"
                           [pageSizeOptions]="[5, 10, 15]"
                           [style]="!isLoadingResults && gridRows && gridRows.length === 0 ? 'visibility:hidden' : ''"></mat-paginator>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>

  <div class="spacer"></div>

  <div mat-dialog-actions align="end"
       [style]="selectedIndex === 1 && confirmRecordType === 'VIRTUAL_EMPLOYEE' ? 'justify-content: space-between' : ''">
    <button *ngIf="selectedIndex === 1 && confirmRecordType === 'VIRTUAL_EMPLOYEE'" mat-raised-button color="primary"
            cdkFocusInitial (click)="createNewVirtualEmployee()"
            [disabled]="progress">
      <mat-icon>add</mat-icon>
      Create new & match
    </button>
    <div>
      <button
        *ngIf="selectedIndex === 0 || confirmRecordType === 'NETSUITE_EMPLOYEE' || confirmRecordType === 'VETSOURCE_EMPLOYEE'"
        mat-button (click)="onNoClick()">Close
      </button>
      <button *ngIf="selectedIndex === 0" mat-raised-button color="primary" cdkFocusInitial (click)="goForward()">
        Next
      </button>
      <button
        *ngIf="selectedIndex === 1 && confirmRecordType !== 'NETSUITE_EMPLOYEE' && confirmRecordType !== 'VETSOURCE_EMPLOYEE'"
        mat-button (click)="goBack()"
        [disabled]="progress">Back
      </button>
      <button *ngIf="selectedIndex === 1" mat-raised-button color="primary" cdkFocusInitial (click)="confirmMatch()"
              [disabled]="!selectedRow || progress">
        <mat-icon>check</mat-icon>
        Confirm match
      </button>
    </div>
  </div>
</div>
