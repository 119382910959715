import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalConstants} from '../global-constants';

@Injectable({
  providedIn: 'root'
})
export class MasterChangeHistoryService {

  constructor(private http: HttpClient) {
  }

  public findAll(id, virtual: boolean, page: number, size: number) {
    const prefix = virtual ? 'virtual-employees' : 'employees';
    return this.http.get(`${GlobalConstants.apiURL}/api/${prefix}/history-changes/${id}?page=${page}&size=${size}`);
  }
}
