<h1 mat-dialog-title>Version info</h1>
<div mat-dialog-content>
  <div class="container">
    <h2>Employee UI</h2>
    <div class="row ">
      <div class="col-sm-4"><b>Commit</b></div>
      <div class="col-sm-8">{{data.employeeUiInfo?.commit}}</div>
    </div>
    <div class="row ">
      <div class="col-sm-4"><b>Build #</b></div>
      <div class="col-sm-8">{{data.employeeUiInfo?.buildNo}}</div>
    </div>
    <div class="row ">
      <div class="col-sm-4"><b>Branch</b></div>
      <div class="col-sm-8">{{data.employeeUiInfo?.branch}}</div>
    </div>
  </div>
  <hr class="hr-top-15"/>
  <div class="container padding-top-15">
    <h2>Master APP</h2>
    <div class="row ">
      <div class="col-sm-4"><b>Commit</b></div>
      <div class="col-sm-8">{{data.masterAppInfo?.git?.commit?.id}}</div>
    </div>
    <div class="row ">
      <div class="col-sm-4"><b>Build #</b></div>
      <div class="col-sm-8">{{data.masterAppInfo?.build?.build_number}}</div>
    </div>
    <div class="row ">
      <div class="col-sm-4"><b>Branch</b></div>
      <div class="col-sm-8">{{data.masterAppInfo?.git?.branch}}</div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Close</button>
</div>
