import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalConstants} from '../global-constants';
import {AahaAccount, EmployeeDetails, Employment, Location} from './master.entities.models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterEmployeeService {

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get(`${GlobalConstants.apiURL}/api/employees/`);
  }

  public getEmployeeDetails(id: number): Observable<EmployeeDetails> {
    return this.http.get<EmployeeDetails>(`${GlobalConstants.apiURL}/api/employees/${id}`);
  }

  public getEmployments(id: number): Observable<Array<Employment>> {
    return this.http.get<Array<Employment>>(`${GlobalConstants.apiURL}/api/employees/${id}/employments`)
  }

  public getLocations(id: number, virtual:boolean = false): Observable<Array<Location>> {
    const suffix = virtual ? 'virtual-employees' : 'employees';
    return this.http.get<Array<Location>>(`${GlobalConstants.apiURL}/api/${suffix}/${id}/locations`)
  }

  public toggleExcludeSalary(employeeId: number, employmentId:number, checked: boolean) {
    return this.http.patch(
      `${GlobalConstants.apiURL}/api/employees/${employeeId}/employments/${employmentId}/excludeSalary/${checked}`, {}, { observe: 'response'});
  }

  public updateAahaServiceAccountsExclusions(employeeId: number, employmentId:number, accounts: AahaAccount[]) {
    return this.http.patch(
      `${GlobalConstants.apiURL}/api/employees/${employeeId}/employments/${employmentId}/aahaServiceAccountsExclusions`, accounts, { observe: 'response'});
  }

  public updateAahaProductAccountsExclusions(employeeId: number, employmentId:number, accounts: AahaAccount[]) {
    return this.http.patch(
      `${GlobalConstants.apiURL}/api/employees/${employeeId}/employments/${employmentId}/aahaProductAccountsExclusions`, accounts, { observe: 'response'});
  }
}
