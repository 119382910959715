<h1 mat-dialog-title>Message from {{syncDetails.externalSystem.name}}</h1>
<div mat-dialog-content>

  <div class="container">
    <div class="row padding-bottom-15 padding-top-15">
      <div class="col-sm-12" [innerHTML]="syncDetails.syncMessage">
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Close</button>
</div>
