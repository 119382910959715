import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../login/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {VersionDialog} from './modals/version-dialog';
import {forkJoin, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../global-constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isLoggedIn = false;
  public username: string;
  public userrole: string;
  public userFullName: string;

  constructor(private route: ActivatedRoute, private router: Router,
              private authenticationService: AuthenticationService, private httpClient: HttpClient, public dialog: MatDialog,) {
  }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isUserLoggedIn();
    const user = this.authenticationService.getCurrentUser();
    this.username = user.username;
    this.userrole = user.role;
    this.userFullName = user.fullName;
  }

  handleLogout() {
    this.authenticationService.logout();
  }

  openVersionDialog(): void {
    forkJoin([
      this.httpClient.get('assets/build-info.json', {responseType: 'json'}).pipe(catchError(error => of(error))),
      this.httpClient.get(`${GlobalConstants.apiURL}/actuator/info`, {responseType: 'json'}).pipe(catchError(error => of(error)))
    ])
      .subscribe(results => {
          console.log(results);
          this.dialog.open(VersionDialog, {
            width: '600px',
            data: {
              employeeUiInfo: results[0],
              masterAppInfo: results[1]
            },
          })
        },
        error => {
          console.error(error)
        });
  }
}
