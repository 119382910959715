import {ActivatedRoute, Router} from '@angular/router';
import {MasterEmployeeService} from '../master.employee.service';
import {BaseEvent, EmployeeDetails, EmployeeEvent, Employment} from '../master.entities.models';
import {SpinnerService} from '../../spinner/spinner.service';
import {forkJoin} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Component, OnInit} from '@angular/core';
import {MasterEntitiesService} from '../master.entities.service';
import {DetailsDataService} from './details.data.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  id: number;
  fullName: string;
  employeeDetails: EmployeeDetails = {
    address: {}
  };
  // Employments tab
  employments: Array<Employment> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private masterEmployeeService: MasterEmployeeService,
              private masterEntitiesService: MasterEntitiesService,
              private spinnerService: SpinnerService,
              private toastrService: ToastrService,
              private detailsDataService: DetailsDataService) {
  }


  ngOnInit() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    if (this.route.params) {
      this.route.params.subscribe(params => {
        this.id = params.id;
        this.fullName = params.fullname;
        this.reload();
      });
    }
  }

  private reload() {
    this.spinnerService.showSpinner();
    forkJoin([
      this.masterEmployeeService.getEmployeeDetails(this.id),
      this.masterEmployeeService.getEmployments(this.id)
    ]).subscribe((arg: [EmployeeDetails, Array<Employment>]) => {
        this.employeeDetails = arg[0];
        // change fullName
        // tslint:disable-next-line:max-line-length
        this.fullName = this.employeeDetails.lastName ? `${this.employeeDetails.firstName} ${this.employeeDetails.lastName}` : this.fullName;
        if (!this.employeeDetails.address) {
          this.employeeDetails.address = {};
        }
        this.employments = arg[1];

        // not sure the right way
        this.employments.forEach(e => {
          if (!e.primaryStateTax) e.primaryStateTax = {};
          if (!e.federalTax) e.federalTax = {};
          if (!e.payRate) e.payRate = {};
          if (!e.workAddress) e.workAddress = {};
        });
        this.spinnerService.hideSpinner()

      },
      error => {
        this.spinnerService.hideSpinner();
        this.toastrService.warning('Cannot load data.');
        this.router.navigate(['employees'])
      });
  }

  onUpdate($event: EmployeeEvent) {
    this.router.navigate(['employees/details', this.id, {fullname: `${this.employeeDetails.firstName} ${this.employeeDetails.lastName}`}]);
  }

  onDetailsTabsClick(event, id) {
    this.detailsDataService.changeMessage({id, textLabel: event.tab.textLabel});
  }

  onEmploymentTabEvent($event: BaseEvent) {
    this.router.navigate(['./'], {relativeTo: this.route});
  }

  onTaxTabEvent($event: any) {
    this.router.navigate(['./'], {relativeTo: this.route});
  }
}
