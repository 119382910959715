<h1 mat-dialog-title>Delete Hospital Rev Account</h1>
<div mat-dialog-content>
  <div>
    <p>You're going to delete hospital rev account: "{{ data.name }}"</p>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Confirm</button>
</div>



