import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GlobalConstants} from '../../../global-constants';
import {HttpClient} from '@angular/common/http';
import {ExternalSystemSyncStatus} from './synchronization.model';

@Injectable({
  providedIn: 'root'
})
export class SynchronizationService {

  constructor(private http: HttpClient) {
  }

  public getEmployeeOrigins(employeeId: number, virtual: boolean = false): Observable<Array<ExternalSystemSyncStatus>> {
    const suffix = virtual ? 'virtual-employees' : 'employees';
    return this.http.get<Array<ExternalSystemSyncStatus>>(`${GlobalConstants.apiURL}/api/${suffix}/${employeeId}/origins/`);
  }

  public getEmployeePimsSyncStatus(employeeId: number, locationId: number, originTypeCode: string, virtual: boolean = false):
    Observable<Array<ExternalSystemSyncStatus>> {
    const suffix = virtual ? 'virtual-employees' : 'employees';
    return this.http.get<Array<ExternalSystemSyncStatus>>(`${GlobalConstants.apiURL}/api/${suffix}/${employeeId}/origins/${originTypeCode}/${locationId}/sync/status`);
  }

  public getEmploymentOrigins(employmentId: number): Observable<Array<ExternalSystemSyncStatus>> {
    return this.http.get<Array<ExternalSystemSyncStatus>>(`${GlobalConstants.apiURL}/api/employments/${employmentId}/origins/`);
  }
}



