<mat-drawer-container autosize style="background: white">

  <mat-drawer #drawer [mode]="'side'" position="end">
  </mat-drawer>

  <mat-drawer-content style="padding-top: 15px">
    <h5 class="card-title text-muted">Hospital Rev Accounts</h5>
    <mat-divider></mat-divider>

    <form class="form-inline list-employee-search-header">
      <div class="row">
        <div class="col col-lg-4">
          <mat-form-field class="toast-top-full-width" appearance="standard">
            <mat-label>Search by Rarebreed ID or name</mat-label>
            <input type="text"
                   placeholder=""
                   aria-label="Search"
                   matInput
                   (input)="updateFilter()" [(ngModel)]="filters.searchQuery.value">
          </mat-form-field>
        </div>
        <div class="col col-lg-7">
<!--          <button mat-icon-button color="primary" (click)="drawer.toggle()" class="margin-top-15">-->
<!--            <mat-icon aria-hidden="false">tune</mat-icon>-->
<!--            <span *ngIf="calculateActiveFiltersCount() > 0"-->
<!--                  class="badge badge-small">{{calculateActiveFiltersCount()}}</span>-->
<!--          </button>-->
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1">
          <button mat-fab color="primary" (click)="openCreateDialog()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>

    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="isLoadingResults">
        <mat-spinner [diameter]="50" *ngIf="isLoadingResults"></mat-spinner>
      </div>

      <div class="table-box">
        <table mat-table [dataSource]="dataSource"
               matSort matSortActive="rarebreedId" matSortDisableClear matSortDirection="desc">

          <ng-container matColumnDef="rarebreedId" sticky>
            <th mat-header-cell *matHeaderCellDef>Rarebreed Id</th>
            <td mat-cell *matCellDef="let row">
          <span *ngIf="row.rarebreedId">
            {{row.rarebreedId}}
          </span>
              <mat-icon *ngIf="!row.rarebreedId" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Internal Id</th>
            <td mat-cell *matCellDef="let row">
              {{row.id}}
              <mat-icon *ngIf="!row.id" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">
              {{row.name}}
              <mat-icon *ngIf="!row.name" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon">
                <mat-icon *ngIf="!element.loading">more_vert</mat-icon>
                <mat-spinner *ngIf="element.loading" diameter="20"></mat-spinner>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openCreateDialog(element)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="openDeleteDialog(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row (click)="editVirtualEmployee($event, row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
