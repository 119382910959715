<div class="table-container">
  <div class="table-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner [diameter]="50" *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="table-box">
    <table mat-table [dataSource]="data">

      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>Commit ID</th>
        <td mat-cell *matCellDef="let row">{{row.changesByCommit.commit.id.majorId}}</td>
      </ng-container>

      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef>Author</th>
        <td mat-cell *matCellDef="let row">{{row.changesByCommit.commit.author}}</td>
      </ng-container>

      <ng-container matColumnDef="changeType">
        <th mat-header-cell *matHeaderCellDef>Change Type</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.changesByObject[0].newObjects.length > 0">
            Created
          </div>
          <div *ngIf="row.changesByObject[0].propertyChanges.length > 0">
            Property changed
          </div>
          <div *ngIf="row.changesByObject[0].objectsRemoved.length > 0">
            Removed
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="typeName">
        <th mat-header-cell *matHeaderCellDef>Type Name</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.changesByObject[0].globalId.typeName">
            {{row.changesByObject[0].globalId.typeName.substring(row.changesByObject[0].globalId.typeName.lastIndexOf('.') + 1)}}
          </span>
          <mat-icon *ngIf="!row.changesByObject[0].globalId.typeName" class="font-18">remove</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="commitDate">
        <th mat-header-cell *matHeaderCellDef>Commit Date</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="!row.changesByCommit.commit.commitDate">
             <mat-icon class="font-18">remove</mat-icon>
          </span>
          <span *ngIf="row.changesByCommit.commit.commitDate">{{row.changesByCommit.commit.commitDate | date:'MM-dd-yyyy HH:mm:ss'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="star" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon">
            <mat-icon *ngIf="!element.loading">more_vert</mat-icon>
            <mat-spinner *ngIf="element.loading" diameter="20"></mat-spinner>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openViewDialog(element)">
              <mat-icon>remove_red_eye</mat-icon>
              <span>View</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
