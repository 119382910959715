import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionTimerService} from './session-timer.service';

@Injectable()
export class SessionTimerHttpInterceptor implements HttpInterceptor {
  constructor(private readonly timerService: SessionTimerService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.timerService.dateOfLastInteraction = new Date();
    return next.handle(request);
  }
}
