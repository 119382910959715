<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay()}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content style-modal-spinner">
      <div class="text-center">
        <div class="spinner-border style-modal-spinner-dim" role="status">
        </div>
      </div>
    </div>
  </div>
</div>
