<div class="row">
  <div class="col col-lg-7">
    <div *ngIf="!isNew()" class="container">
      <h2 class="card-title text-muted">{{employment.rarebreedEmployeeId}} @ {{ employment.location?.name }}</h2>
      <h3 class="card-subtitle mb-2 text-muted">Job Details</h3>
      <hr style="margin: 0px !important;"/>
      <br/>
    </div>

    <div class="container">
      <div *ngIf="employment">

        <div class="row style-row-gap-10" *ngIf="isNew()">
          <div class="col col-lg-12">
            <mat-form-field style="width: 100%">
              <mat-label>Location</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.location" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let location of masterEntities.locations | async | sortByName"
                            [value]="location">{{location.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Employee Status</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.employeeStatus" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let employeeStatus of masterEntities.employeeStatuses | async"
                            [value]="employeeStatus">{{employeeStatus.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Employee Type</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.employeeType" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let employeeType of masterEntities.employeeTypes | async"
                            [value]="employeeType">{{employeeType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-12">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Status change reason</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.statusChangeReason">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Hire Date</mat-label>
              <input [disabled]="true" matInput [formControl]="hiredOn" [matDatepicker]="hireDate">
              <mat-datepicker-toggle *ngIf="hiredOn.value != null" matSuffix (click)="hiredOn.setValue(null)">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="hireDate"></mat-datepicker-toggle>
              <mat-datepicker #hireDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Eligible for rehire</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.eligibleForRehire">
                <mat-option [value]=""></mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Effective Date</mat-label>
              <input [disabled]="true" matInput [formControl]="effectiveOn" [matDatepicker]="effectiveOnId">
              <mat-datepicker-toggle *ngIf="effectiveOn.value != null" matSuffix (click)="effectiveOn.setValue(null);">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="effectiveOnId"></mat-datepicker-toggle>
              <mat-datepicker #effectiveOnId></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Termination Date</mat-label>
              <input [disabled]="true" matInput [formControl]="terminatedOn" [matDatepicker]="terminatedOnId">
              <mat-datepicker-toggle *ngIf="terminatedOn.value != null" matSuffix (click)="terminatedOn.setValue(null);">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="terminatedOnId"></mat-datepicker-toggle>
              <mat-datepicker #terminatedOnId></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-12">
            <mat-form-field style="width: 100%">
              <mat-label>Job Title</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.jobTitle" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let jobTitle of masterEntities.jobTitles | async"
                            [value]="jobTitle">{{jobTitle.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Department</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.department" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let department of masterEntities.departments | async"
                            [value]="department">{{department.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Position</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.position" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let position of masterEntities.positions | async"
                            [value]="position">{{position.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-12">
            <mat-form-field style="width: 100%">
              <mat-label>Manager</mat-label>
              <mat-select [disabled]="true" id="manager" name="manager" [(ngModel)]="employment.manager" [compareWith]="compareOption" [disabled]="true">
                <mat-option [value]="employment.manager">
                  {{(employment.manager?.firstName || '') + ' ' + (employment.manager?.lastName || '') }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">

          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Work Phone</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.workAddress.workPhone">
            </mat-form-field>
          </div>

          <div class="col col-lg-2">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Extension</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.workAddress.workPhoneExtension">
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Work Email</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.workAddress.workEmail">
            </mat-form-field>
          </div>
        </div>

        <div class="row">

          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Benefit Class</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.benefitClass" [disabled]="true">
            </mat-form-field>
          </div>

          <div class="col col-lg-6 padding-top-12">
            <mat-form-field style="width: 100%">
              <mat-label>Benefit Class Effective Date</mat-label>
              <input [disabled]="true" matInput [formControl]="benefitClassEffectiveDate" [matDatepicker]="effectiveDateId" disabled>
              <mat-datepicker-toggle *ngIf="benefitClassEffectiveDate.value != null" matSuffix (click)="benefitClassEffectiveDate.setValue(null)"
                                     [disabled]="true">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="effectiveDateId" [disabled]="true"></mat-datepicker-toggle>
              <mat-datepicker #effectiveDateId></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <br/><br/>
        <h3 class="card-subtitle mb-2 text-muted">Salary</h3>
        <hr style="margin: 0px !important;"/>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Pay Rate</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.payRate.payRate">
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Rate Per</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.payRate.ratePer">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Pay Type</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.payRate.payType" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let payType of masterEntities.payTypes | async"
                            [value]="payType">{{payType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Pay Frequency</mat-label>
              <mat-select [disabled]="true" [(ngModel)]="employment.payRate.payFrequency" [compareWith]="compareOption">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let payFrequency of masterEntities.payFrequencies | async"
                            [value]="payFrequency">{{payFrequency.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <br/><br/>
        <h3 class="card-subtitle mb-2 text-muted">ProSal</h3>
        <hr style="margin: 0px !important;"/>
        <br/>
        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field style="width: 100%">
              <mat-label>Effective Date</mat-label>
              <input [disabled]="true" matInput [formControl]="prosalEffectiveDate" [matDatepicker]="prosalEffectiveOnId">
              <mat-datepicker-toggle *ngIf="prosalEffectiveDate.value != null" matSuffix>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="prosalEffectiveOnId"></mat-datepicker-toggle>
              <mat-datepicker #prosalEffectiveOnId></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Practice Type</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.prosalInfo.practiceType">
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Contract Type</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.prosalInfo.contractType">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Service Percent</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.prosalInfo.servicePercent">
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Product Percent</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.prosalInfo.productPercent">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field>
              <mat-label>Service Exclusions</mat-label>
              <mat-select [formControl]="serviceExclusions" multiple (selectionChange)="onServiceExclusionsChange($event)">
                <mat-option *ngFor="let account of masterEntities.aahaAccounts | async | aahaAccountRevenueCategory : 'SERVICE'"
                            [value]="account.id">{{account.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-form-field>
              <mat-label>Product Exclusions</mat-label>
              <mat-select [formControl]="productExclusions" multiple (selectionChange)="onProductExclusionsChange($event)">
                <mat-option *ngFor="let account of masterEntities.aahaAccounts | async | aahaAccountRevenueCategory : 'PRODUCT'"
                            [value]="account.id">{{account.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-6">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Digital Imaging Commission</mat-label>
              <input [disabled]="true" matInput [(ngModel)]="employment.prosalInfo.diCommission">
            </mat-form-field>
          </div>

          <div class="col col-lg-6">
            <mat-checkbox style="padding-top: 20px" [checked]="employment.prosalInfo.excludeSalary" (change)="onExcludeSalaryChange($event)">
              Exclude Salary
            </mat-checkbox>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col col-lg-4 offset-lg-1 padding-top-15" *ngIf="employment" [hidden]="!authService.isAdmin() || isNew()">
    <app-employment-sync-widget [employmentId]="employment.id"></app-employment-sync-widget>
  </div>
</div>
