<app-menu></app-menu>
<app-spinner></app-spinner>
<div class="container mw-100 h-100">
  <div class="row h-100">
    <div class="col col-lg-2 bg-light">
      <app-secondarymenu></app-secondarymenu>
    </div>

    <div class="col col-lg-10" style="padding-bottom: 15px;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
