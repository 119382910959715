<!--Begin Employee Profile -->
<h5 class="card-title text-muted" style="padding-top: 15px">
  <a routerLink="/employees/virtual-employee" style="border: none !important;">..</a>/{{virtualEmployee.name}} ({{virtualEmployee.id}})
</h5>
<mat-divider></mat-divider>
<mat-tab-group mat-stretch-tabs (selectedTabChange)="onDetailsTabsClick($event, virtualEmployee.id)">
  <mat-tab label="Personal Information">
    <app-virtual-employee-form [virtualEmployee]="virtualEmployee" (virtualEmployeeUpdate)="onUpdate($event)"></app-virtual-employee-form>
  </mat-tab>
  <mat-tab label="PIMS Accounts">
    <pims-accounts-tab [virtual]="true" [employeeId]="virtualEmployee.id" [employeeName]="virtualEmployee.name"></pims-accounts-tab>
  </mat-tab>
  <mat-tab label="Change History">
    <change-history-tab [virtual]="true"></change-history-tab>
  </mat-tab>
</mat-tab-group>


