<div id="session-expiration-alert" [hidden]="!showModal"
     class="sea-modal" role="dialog" tabindex="-1" aria-modal="true" aria-label="session-expiration-alert">
  <div role="document" class="sea-modal-content" id="expiring" *ngIf="!expired">
    <div class="sea-modal-header">Session is about to expire</div>
    <div class="sea-modal-body">
      <p>
        Your session will expire in {{ asDate(sessionTimer.remainSeconds$ | async) | date: 'mm:ss' }} minutes.<br/>
        Are you still working here?
      </p>
    </div>
    <div class="sea-modal-footer">
      <button type="button" class="btn btn-secondary" (click)="logout()">
        No
      </button>
      <button type="button" class="btn btn-primary" (click)="continue()">
        Yes
      </button>
    </div>
  </div>
</div>

<div class="sea-modal-background" [hidden]="!showModal"></div>
