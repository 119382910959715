import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HttpInterceptorService} from './http-interceptor.service';
import {MenuComponent} from './menu/menu.component';
import {LogoutComponent} from './logout/logout.component';
import {EmployeeListComponent} from './employee/list/employee-list.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {SecondarymenuComponent} from './secondarymenu/secondarymenu.component';
import {MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular'; // Import MsalInterceptor
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '../environments/environment';
import {SpinnerComponent} from './spinner/spinner.component';
import {MasterComponent} from './employee/master.component';
import {DetailsComponent} from './employee/details/details.component';
import {PersonalTabComponent} from './employee/details/personal/personal.component';
import {HidePipe} from './hide.pipe';
import {ToastrModule} from 'ngx-toastr';
import {EmploymentsTabComponent} from './employee/details/employments/employments-tab.component';
import {
  AahaAccountRevenueCategoryPipe,
  EmploymentComponent,
  SortByNamePipe
} from './employee/details/employments/employment.component';
import {SessionExpirationAlert} from './session-expiration-alert/session-expiration-alert.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {ChangeHistoryComponent} from './employee/details/change-history/change-history.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {ViewDiffDialog} from './employee/details/change-history/modals/view/view-diff-dialog';
import {StateTaxComponent} from './employee/details/tax/state-tax.component';
import {FederalTaxComponent} from './employee/details/tax/federal-tax.component';
import {RegularEmploymentsPipe, TaxTabComponent} from './employee/details/tax/tax-tab.component';
import {EmploymentSynchronizationComponent} from './employee/details/synchronization/employment-synchronization.component';
import {EmployeeSynchronizationComponent} from './employee/details/synchronization/employee-synchronization.component';
import {FederalTaxSynchronizationComponent} from './employee/details/synchronization/federal-tax-synchronization.component';
import {ViewSyncDetailsComponent} from './employee/details/synchronization/modals/view/view-sync-details.component';
import {VersionDialog} from './menu/modals/version-dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import {UnmatchedRecordsComponent} from './unmatched-records/unmatched-records.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatchDialog} from './unmatched-records/modals/view/match-dialog';
import {VirtualEmployeeComponent} from './virtual-employee/virtual-employee.component';
import {CreateVirtualEmployeeDialog} from './virtual-employee/modals/create/create-virtual-employee-dialog';
import {DeleteVirtualEmployeeDialog} from './virtual-employee/modals/delete/delete-virtual-employee-dialog';
import {VirtualEmployeeDetailsComponent} from './virtual-employee/details/virtual-employee-details.component';
import {VirtualEmployeeFormComponent} from './virtual-employee/form/virtual-employee-form.component';
import {MatRadioModule} from '@angular/material/radio';
import {UnmapEmployeeDialog} from './employee/details/synchronization/modals/unmap/unmap-employee-dialog';
import {CreateEmployeeDialog} from './employee/details/synchronization/modals/create/create-employee-dialog';
import {PimsAccountsComponent} from './employee/details/pims-accounts/pims-accounts.component';
import {PimsSynchronizationComponent} from './employee/details/synchronization/pims-synchronization.component';
import {MatTooltipModule} from '@angular/material/tooltip';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    LogoutComponent,
    EmployeeListComponent,
    SecondarymenuComponent,
    SpinnerComponent,
    MasterComponent,
    DetailsComponent,
    PersonalTabComponent,
    EmploymentsTabComponent,
    EmploymentComponent,
    TaxTabComponent,
    FederalTaxComponent,
    StateTaxComponent,
    ChangeHistoryComponent,
    ViewDiffDialog,
    HidePipe,
    EmployeeSynchronizationComponent,
    EmploymentSynchronizationComponent,
    PimsSynchronizationComponent,
    FederalTaxSynchronizationComponent,
    ViewSyncDetailsComponent,
    FederalTaxSynchronizationComponent,
    FederalTaxSynchronizationComponent,
    VersionDialog,
    UnmatchedRecordsComponent,
    VirtualEmployeeComponent,
    SortByNamePipe,
    MatchDialog,
    CreateVirtualEmployeeDialog,
    DeleteVirtualEmployeeDialog,
    RegularEmploymentsPipe,
    DeleteVirtualEmployeeDialog,
    VirtualEmployeeDetailsComponent,
    VirtualEmployeeFormComponent,
    UnmapEmployeeDialog,
    CreateEmployeeDialog,
    PimsAccountsComponent,
    AahaAccountRevenueCategoryPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 7000,
      positionClass: 'toast-top-right',
      newestOnTop: true,
      progressBar: true
    }),
    SessionExpirationAlert.forRoot({totalMinutes: 30}),
    MsalModule.forRoot(new PublicClientApplication({
      auth: environment.auth,
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ])
    }),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatStepperModule,
    MatSortModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatBadgeModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }
    },
    MsalGuard,
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
