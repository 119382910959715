<div class="container pims-container">
  <div class="table-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner [diameter]="50" *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div *ngIf="!isLoadingResults" class="content">
    <div class="row" *ngFor="let location of data">
      <div class="col col-lg-12">
        <div class="container padding-top-15">
          <h2 class="card-title text-muted">@ {{location.name}}</h2>
          <hr style="margin: 0px !important;"/>
          <br/>
        </div>
        <div *ngIf="location">
          <app-pims-sync-widget [employeeId]="employeeId" [locationId]="location.id"
                                [virtual]="virtual"></app-pims-sync-widget>
        </div>
      </div>
    </div>
    <br/>
  </div>
  <div *ngIf="!isLoadingResults&& data.length == 0" class="content text-center">
    <h3>No one location found</h3>
  </div>
  <button *ngIf="!isLoadingResults" mat-stroked-button color="primary" class="bottom-button"
          (click)="openMatchDialog(false)">
    Add mapping
  </button>
</div>
