<div class="container" *ngIf="virtualEmployee">
  <div class="row">
    <div class="col col-lg-7">
      <div class="row style-row-gap-10">

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Name</mat-label>
            <input matInput required [(ngModel)]="virtualEmployee.name">
          </mat-form-field>
        </div>
      </div>

      <br/>
      <div class="row style-row-gap-10">
        <div class="col col-lg-10">
          <button mat-raised-button color="primary" (click)="save()"
                  [disabled]="!virtualEmployee.name">
            <mat-icon>check</mat-icon>
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="col col-lg-4 offset-lg-1 padding-top-15">
      <app-employee-sync-widget [employeeId]="virtualEmployee.id" [virtual]="true"></app-employee-sync-widget>
    </div>
  </div>
</div>
