<!--<div *ngIf='IS_AUTHENTICATED'>
	<nav class="navbar navbar-expand navbar-dark bg-dark">
		<a href="#" class="navbar-brand">Rarebreed Vet</a>
		<div class="navbar-nav mr-auto"></div>
	</nav>
</div>
-->
<router-outlet></router-outlet>
<!-- <div class="container mt-3">
	<router-outlet></router-outlet>
</div> -->
<session-expiration-alert [alertAt]="300"></session-expiration-alert>
