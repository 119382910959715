import {Inject, Injectable} from '@angular/core';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {ConfigToken, SessionExpirationConfig} from '../models/session-expiration-config';
import {GlobalConstants} from '../../global-constants';

@Injectable()
export class SessionTimerService {
  private readonly _timeoutSeconds: number;
  private timerSubscription!: Subscription;
  private timer: Observable<number> = interval(1000);
  private _remainSeconds = new Subject<number>();
  public dateOfLastInteraction: Date = new Date();
  /**
   * Observable to get session remaining time (in seconds).
   * Subscribers need to unsubscribe to it before hosting element is destroyed.
   */
  remainSeconds$ = this._remainSeconds.asObservable();

  constructor(@Inject(ConfigToken) readonly config: SessionExpirationConfig) {
    this._timeoutSeconds = config.totalMinutes * 60;
  }

  startTimer(expiresOn: Date): void {
    this.stopTimer();
    this.timerSubscription = this.timer.subscribe(n => {
      const remainSeconds = (expiresOn.getTime() - new Date().getTime()) / 1000;
      this._remainSeconds.next(remainSeconds);
    });
  }

  stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer(): void {
    const expiresOn = sessionStorage.getItem(GlobalConstants.EXPIRES_ON);
    if (expiresOn) {
      this.startTimer(new Date(expiresOn));
    }
  }
}
