import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SortDirection} from '@angular/material/sort';
import {GlobalConstants} from '../global-constants';
import {Observable} from 'rxjs';
import {NetsuiteEmployeesPage} from './netsuite-employee.models';

@Injectable({
  providedIn: 'root'
})
export class NetsuiteEmployeeService {

  constructor(private http: HttpClient) {
  }

  findAll(page: number, size: number, sort: string, order: SortDirection, search, excludeMatched = false):
    Observable<NetsuiteEmployeesPage> {
    const sortParameter = sort ? `&sort=${sort},${order}` : '';
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<NetsuiteEmployeesPage>(`${GlobalConstants.apiURL}/api/netsuite-employees?page=${page}&size=${size}&excludeMatched=${excludeMatched}${sortParameter}${searchParameter}`);
  }
}
