<h1 mat-dialog-title>Create/Edit a Hospital Rev Account</h1>
<mat-progress-bar *ngIf="progress"
                  [color]="'primary'"
                  [mode]="'indeterminate'">
</mat-progress-bar>

<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 col-xs-12">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Name</mat-label>
        <input matInput [disabled]="progress" required [(ngModel)]="data.name">
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!data.name" cdkFocusInitial>Submit</button>
</div>
