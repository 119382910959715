import {ActivatedRoute, Router} from '@angular/router';
import {SpinnerService} from '../../spinner/spinner.service';
import {Component, OnInit} from '@angular/core';
import {DetailsDataService} from '../../employee/details/details.data.service';
import {VirtualEmployee} from '../virtual-employee.models';
import {EmployeeEvent} from '../../employee/master.entities.models';

@Component({
  selector: 'app-virtual-employee-details',
  templateUrl: './virtual-employee-details.component.html',
  styleUrls: ['./virtual-employee-details.component.css']
})
export class VirtualEmployeeDetailsComponent implements OnInit {

  virtualEmployee: VirtualEmployee;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private spinnerService: SpinnerService,
              private detailsDataService: DetailsDataService) {
  }

  ngOnInit() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.route.params.subscribe(params => {
      this.virtualEmployee = {id: params.id, name: params.name, rarebreedId: params.rarebreedId} as VirtualEmployee;
    });
  }

  onDetailsTabsClick(event, id) {
    this.detailsDataService.changeMessage({id, textLabel: event.tab.textLabel});
  }

  onUpdate($event: EmployeeEvent) {
    this.router.navigate(['employees/virtual-employee', this.virtualEmployee.id, this.virtualEmployee]);
  }
}
