<h1 mat-dialog-title>Unmap Employee</h1>
<mat-progress-bar *ngIf="progress"
                  [color]="'primary'"
                  [mode]="'indeterminate'">
</mat-progress-bar>
<div mat-dialog-content>
  <br/>
  <div>
    <p>You're going to unmap employee: "{{ data.name }}"</p>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="progress" cdkFocusInitial>Confirm</button>
</div>
