import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SortDirection} from '@angular/material/sort';
import {GlobalConstants} from '../global-constants';
import {VirtualEmployee, VirtualEmployeesPage} from './virtual-employee.models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VirtualEmployeeService {

  constructor(private http: HttpClient) {
  }

  findAll(page: number, size: number, sort: string, order: SortDirection, search, excludeMatched = false):
    Observable<VirtualEmployeesPage> {
    const sortParameter = sort ? `&sort=${sort},${order}` : '';
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<VirtualEmployeesPage>(`${GlobalConstants.apiURL}/api/virtual-employees?page=${page}&size=${size}&excludeMatched=${excludeMatched}${sortParameter}${searchParameter}`);
  }

  create(data): Observable<VirtualEmployee> {
    return this.http.post<VirtualEmployee>(`${GlobalConstants.apiURL}/api/virtual-employees/`, data);
  }

  update(id: number, data) {
    return this.http.put(`${GlobalConstants.apiURL}/api/virtual-employees/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${GlobalConstants.apiURL}/api/virtual-employees/${id}`);
  }
}
