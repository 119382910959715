import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {map} from 'rxjs/operators';
import {SynchronizationDirective} from './synchronization.directive';


@Component({
  selector: 'app-employee-sync-widget',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.css']
})
export class EmployeeSynchronizationComponent extends SynchronizationDirective implements OnChanges, OnDestroy {
  @Input() employeeId: number;
  @Input() virtual: boolean;
  subscription;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this.subscription = this.detailsDataService.currentMessage.subscribe((msg: any) => {
      if (msg && msg.id && msg.textLabel === 'Refresh Sync') {
        this.init();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.employeeId = changes.employeeId.currentValue as number;
    if (this.employeeId) {
      this.init()
    }
  }

  private init() {
    this.synchronizationService.getEmployeeOrigins(this.employeeId, this.virtual)
      .pipe(map(items => items.map(item => this.convert(item)))).subscribe(t => {
      this.rows.next(t);
    }, error => {
      console.log(error);
    });
  }
}

