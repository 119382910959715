import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {MsalGuard} from '@azure/msal-angular';
import {EmployeeListComponent} from './employee/list/employee-list.component';
import {DetailsComponent} from './employee/details/details.component';
import {MasterComponent} from './employee/master.component';
import {UnmatchedRecordsComponent} from './unmatched-records/unmatched-records.component';
import {VirtualEmployeeComponent} from './virtual-employee/virtual-employee.component';
import {VirtualEmployeeDetailsComponent} from './virtual-employee/details/virtual-employee-details.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: LoginComponent},
  {path: 'logout', component: LogoutComponent, canActivate: [MsalGuard]},
  {
    path: 'employees',
    component: MasterComponent,
    canActivate: [MsalGuard],
    children: [
      {path: '', redirectTo: 'list', pathMatch: 'full'},
      {path: 'list', component: EmployeeListComponent},
      {path: 'unmatched-records', component: UnmatchedRecordsComponent},
      {path: 'virtual-employee', component: VirtualEmployeeComponent},
      {path: 'details/:id', component: DetailsComponent},
      {path: 'virtual-employee/:id', component: VirtualEmployeeDetailsComponent}
    ]
  },
  {path: '**', redirectTo: 'employees'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
