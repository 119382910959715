import { DatePipe } from '@angular/common';
import {
  Input,
  Component,
  OnInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter, OnDestroy
} from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import {
  BaseEvent,
  Employment, FederalAmountType, FederalFilingStatus,
  FederalTax,
  TaxForm
} from '../../master.entities.models';
import {compareById, compareYesNoBoolean} from '../../utils';
import {MasterEntitiesService} from '../../master.entities.service';
import {Observable} from 'rxjs';
import {SpinnerService} from '../../../spinner/spinner.service';
import {MasterEmployeeService} from '../../master.employee.service';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../login/auth.service';
import {DetailsDataService} from '../details.data.service';

@Component({
  selector: 'app-federal-tax',
  templateUrl: './federal-tax.component.html',
  styleUrls: ['./federal-tax.component.css']
})
export class FederalTaxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() employments: Array<Employment>;
  @Output() federalTaxEventEmitter = new EventEmitter<BaseEvent>();

  federalTaxInfo: FederalTax;
  taxForms: Observable<TaxForm[]>;
  federalTaxFilingStatuses: Observable<FederalFilingStatus[]>;
  federalTaxAmountTypes: Observable<FederalAmountType[]>;
  subscription;

  constructor(public elementRef: ElementRef,
              private route: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private masterEntitiesService: MasterEntitiesService,
              private masterEmployeeService: MasterEmployeeService,
              private spinnerService: SpinnerService,
              private toastrService: ToastrService,
              public authService: AuthenticationService,
              private detailsDataService: DetailsDataService) { }

  ngOnInit() {
    this.subscription = this.detailsDataService.currentMessage.subscribe((msg: any) => {
      if (msg && msg.id && msg.textLabel === 'Tax') {
        this.taxForms = this.masterEntitiesService.getTaxForms();
        this.federalTaxFilingStatuses = this.masterEntitiesService.getFederalFilingStatuses();
        this.federalTaxAmountTypes = this.masterEntitiesService.getFederalAmountTypes();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.employments = changes.employments.currentValue as Array<Employment>;
    this.federalTaxInfo = (this.employments && this.employments.length > 0) ? this.employments[0].federalTax : {};
  }

  public compareFn(item1, item2): boolean {
    return compareById(item1, item2);
  }

  public compareByBool(item1, item2): boolean {
    return compareYesNoBoolean(item1, item2);
  }
}
