import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private _spinnerModalDisplay: string;

  constructor() { }

  showSpinner() {
    this._spinnerModalDisplay = 'block';
  }

  hideSpinner() {
    this._spinnerModalDisplay = 'none';
  }


  get spinnerModalDisplay(): string {
    return this._spinnerModalDisplay;
  }
}
