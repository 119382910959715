import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VirtualEmployee} from '../../virtual-employee.models';
import {VirtualEmployeeService} from '../../virtual-employee.service';

// tslint:disable-next-line:no-empty-interface
export interface DialogData extends VirtualEmployee {
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'create-virtual-employee-dialog',
  templateUrl: 'create-virtual-employee-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class CreateVirtualEmployeeDialog {
  progress = false;
  virtualEmployee: VirtualEmployee;

  constructor(
    public dialogRef: MatDialogRef<CreateVirtualEmployeeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public virtualEmployeeService: VirtualEmployeeService,
    private _snackBar: MatSnackBar) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    if (this.data) {
      this.virtualEmployee = this.data;
    }
  }

  onSubmit(): void {
    this.progress = true;
    if (this.virtualEmployee && this.virtualEmployee.id) {
      this.virtualEmployeeService.update(this.virtualEmployee.id, this.virtualEmployee)
        .subscribe(
          response => {
            this.progress = false;
            this.dialogRef.close(response);
            this._snackBar.open('Hospital Rev Account \'' + this.virtualEmployee.name + '\' was successfully updated!', 'Close', {
              duration: 5000,
            });
          },
          error => {
            console.log(error);
          });
    } else {
      this.virtualEmployeeService.create(this.virtualEmployee)
        .subscribe(
          response => {
            this.progress = false;
            this.dialogRef.close(response);
            this._snackBar.open('Hospital Rev Account \'' + this.virtualEmployee.name + '\' was successfully created!', 'Close', {
              duration: 5000,
            });
          },
          error => {
            console.log(error);
          });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
