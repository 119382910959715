import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTable} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DetailsDataService} from '../details.data.service';
import {MasterEmployeeService} from '../../master.employee.service';
import {Location} from '../../master.entities.models';
import {MatchDialog} from '../../../unmatched-records/modals/view/match-dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pims-accounts-tab',
  templateUrl: './pims-accounts.component.html',
  styleUrls: ['./pims-accounts.component.css']
})
export class PimsAccountsComponent implements OnDestroy {
  displayedColumns: string[] = ['id', 'name'];
  data: Location[] = [];
  subscription;
  isLoadingResults = true;
  pageSize = 25;
  sourceType: string;
  id: number;
  @Input() virtual: boolean;
  @Input() employeeId: number;
  @Input() employeeName: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public masterEmployeeService: MasterEmployeeService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private detailsDataService: DetailsDataService) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.route.url.subscribe(xps => {
      if (xps[0].path === 'virtual-employee') {
        this.sourceType = 'VIRTUAL_EMPLOYEE';
      } else {
        this.sourceType = 'EMPLOYEE';
      }
    });
    this.subscription = this.detailsDataService.currentMessage.subscribe((msg: any) => {
      if (msg && msg.id && msg.textLabel === 'PIMS Accounts') {
        this.subscribe(msg.id);
      }
    });
  }

  subscribe(id) {
    this.id = id;
    this.isLoadingResults = true;
    // tslint:disable-next-line:max-line-length
    return this.masterEmployeeService.getLocations(id, this.virtual)
      .subscribe(data => {
        this.isLoadingResults = false;
        // @ts-ignore
        this.data = data;
      });
  }

  openMatchDialog(unmap: boolean = false): void {
    const dialogRef = this.dialog.open(MatchDialog, {
      width: '800px',
      height: '670px',
      data: {
        systemCode: 'VETSOURCE',
        confirmRecordType: 'VETSOURCE_EMPLOYEE',
        selectedIndex: 1,
        fullName: this.employeeName,
        searchQuery: this.employeeName,
        originalId: this.employeeId,
        sourceType: this.sourceType,
        unmap
      },
    });

    // tslint:disable-next-line:ter-arrow-parens
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subscribe(this.id);
        this.detailsDataService.changeMessage({id: 1, textLabel: 'Refresh Sync'})
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
