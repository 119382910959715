import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UnmatchedRecordsService} from '../../../../../unmatched-records/unmatched-records.service';
import {DetailsDataService} from '../../../details.data.service';

// tslint:disable-next-line:interface-name
export interface DialogData {
  id: string;
  name: string;
  sourceType: string;
  originType: string;
  recordType: string;
  locationId: number;
  originalId: string
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'unmap-employee-dialog',
  templateUrl: './unmap-employee-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class UnmapEmployeeDialog {
  progress = false;
  constructor(
    public dialogRef: MatDialogRef<UnmapEmployeeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected detailsDataService: DetailsDataService,
    private _snackBar: MatSnackBar,
    public unmatchedRecordsService: UnmatchedRecordsService) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.progress = true;
    const recordType = this.data.originType + '_EMPLOYEE';
    this.unmatchedRecordsService
      .unmatchEmployee(this.data.originalId, this.data.id, this.data.originType, recordType, this.data.sourceType, null, null)
      .subscribe(
        r => {
          this.detailsDataService.changeMessage({id: 1, textLabel: 'Refresh Sync'});
          this._snackBar.open('Employee \'' + this.data.name + '\' successfully unmapped!', 'Close', {
            duration: 5000,
          });
          this.progress = true;
          this.dialogRef.close(r);
        },
        error => {
          this.progress = true;
          console.log(error);
        });
  }
}
