import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UnmatchedRecordsService} from '../unmatched-records/unmatched-records.service';
import {EmployeeListFiltersService} from '../employee/list/employee-list.filters.service';

@Component({
  selector: 'app-secondarymenu',
  templateUrl: './secondarymenu.component.html',
  styleUrls: ['./secondarymenu.component.css']
})
export class SecondarymenuComponent implements OnInit {

  isAdmin = false;
  public userrole: string;
  unmatchedRecordsAmt: any = 0;

  constructor(private route: ActivatedRoute, private router: Router,
              private unmatchedRecordsService: UnmatchedRecordsService,
              private filtersService: EmployeeListFiltersService) {
  }

  ngOnInit() {
    this.userrole = sessionStorage.getItem('USER_ROLE');
    if (this.userrole === 'ADMIN') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    const filters = this.filtersService.getFilters('');
    this.unmatchedRecordsService.count(this.filtersService.buildSearchParam(filters))
      .subscribe(result => {
        this.unmatchedRecordsAmt = result
      }, error => {
        console.log(error);
      });
  }
}
