import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Change} from '../../change-history.model';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'view-diff-dialog',
  templateUrl: 'view-diff-dialog.html',
  styleUrls: ['view-diff-dialog.css'],
})
// tslint:disable-next-line:component-class-suffix
export class ViewDiffDialog {
  constructor(
    public dialogRef: MatDialogRef<ViewDiffDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Change) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
