import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SynchronizationService} from './synchronization.service';
import {BehaviorSubject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ViewSyncDetailsComponent} from './modals/view/view-sync-details.component';
import {ExternalSystemSyncStatus} from './synchronization.model';
import {MatchDialog} from '../../../unmatched-records/modals/view/match-dialog';
import {ActivatedRoute} from '@angular/router';
import {DetailsDataService} from '../details.data.service';
import {UnmatchedRecordsService} from '../../../unmatched-records/unmatched-records.service';
import {UnmapEmployeeDialog} from './modals/unmap/unmap-employee-dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CreateEmployeeDialog} from './modals/create/create-employee-dialog';

@Directive()
export abstract class SynchronizationDirective implements OnInit {
  id: string;
  name: string;
  sourceType: string;
  item: ExternalSystemSyncStatus;
  rows: BehaviorSubject<Array<SyncStatusObject>> = new BehaviorSubject(null);
  @Input() locationId: number;
  @Output() onSynchronize = new EventEmitter<ExternalSystemSyncStatus>();

  constructor(protected synchronizationService: SynchronizationService,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected detailsDataService: DetailsDataService,
              private _snackBar: MatSnackBar,
              public unmatchedRecordsService: UnmatchedRecordsService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.name = params.name ? params.name : params.fullname;
    });
    this.route.url.subscribe(xps => {
      if (xps[0].path === 'virtual-employee') {
        this.sourceType = 'VIRTUAL_EMPLOYEE';
      } else {
        this.sourceType = 'EMPLOYEE';
      }
    });
  }

  calcColor(row: ExternalSystemSyncStatus): string {
    if (row.syncStatus === 'DONE') {
      return 'text-success';
    } else if (row.syncStatus === 'FAILED') {
      return 'text-danger';
    } else if (row.syncStatus === 'PARTIALLY_DONE') {
      return 'text-warning';
    }
    return 'text-secondary';
  }

  messageFrom(row): string {
    return row.item.direction === 'PUSH' ? 'from Employee Portal' : ('from ' + row.item.externalSystem.name);
  }

  openViewDialog(text): void {
    this.dialog.open(ViewSyncDetailsComponent, {
      width: '600px',
      data: text,
    });
  }

  protected convert(item: ExternalSystemSyncStatus): SyncStatusObject {
    return {
      color: this.calcColor(item),
      triggeredAt: item.triggeredAt,
      triggeredBy: item.triggeredBy,
      item
    };
  }

  onSync(object: SyncStatusObject) {
    this.item = object.item;
    this.onSynchronize.emit(this.item);
  }

  openMatchDialog(item: ExternalSystemSyncStatus, unmap: boolean = false): void {
    const dialogRef = this.dialog.open(MatchDialog, {
      width: '800px',
      height: '670px',
      data: {
        systemCode: 'NETSUITE',
        confirmRecordType: 'NETSUITE_EMPLOYEE',
        selectedIndex: 1,
        fullName: this.name,
        searchQuery: this.name,
        originalId: this.id,
        sourceType: this.sourceType,
        unmap
      },
    });

    // tslint:disable-next-line:ter-arrow-parens
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.detailsDataService.changeMessage({id: 1, textLabel: 'Refresh Sync'})
      }
    });
  }

  unmap(item: ExternalSystemSyncStatus): void {
    const dialogRef = this.dialog.open(UnmapEmployeeDialog, {
      width: '550px',
      data: {
        id: this.id,
        name: this.name,
        sourceType: this.sourceType,
        originType: item.externalSystem.code,
        locationId: this.locationId,
        originalId: item.originalId
      },
    });
    // tslint:disable-next-line:ter-arrow-parens
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.detailsDataService.changeMessage({id: this.id, textLabel: 'PIMS Accounts'});
      }
    });
  }

  createNew(item: ExternalSystemSyncStatus): void {
    const dialogRef = this.dialog.open(CreateEmployeeDialog, {
      width: '550px',
      data: {
        id: this.id,
        originalId: item.originalId,
        name: this.name,
        sourceType: this.sourceType
      },
    });
  }
}

interface SyncStatusObject {
  color: string,
  triggeredBy?: string,
  triggeredAt?: Date,
  item: ExternalSystemSyncStatus
}
