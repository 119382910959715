import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

// tslint:disable-next-line:interface-name
export interface DialogData {
  id: string;
  name: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'delete-virtual-employee-dialog',
  templateUrl: './delete-virtual-employee-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DeleteVirtualEmployeeDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteVirtualEmployeeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
