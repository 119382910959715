export const environment = {
  production: true,
  apiUrl: 'https://api.master.devrbcompanion.com',
  masterUrl: 'https://master.devrbcompanion.com',
  auth: {
    clientId: '8a55431a-b934-4450-b34b-03bfb3ec1f02', // This is your client ID
    authority: 'https://login.microsoftonline.com/4311f7b0-1d4f-429a-b2da-ec3498aded90', // This is your tenant ID
    redirectUri: 'https://employee.devrbcompanion.com'// This is your redirect URI
  },
};
