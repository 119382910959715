<div class="container col-lg-6">
  <div class="row style-filler-row"></div>
  <div class="col-lg-12">
    <a href="https://rarebreedvet.com/"><img class="img-responsive style-login-logo" alt="Rarebreed Veterinary Partners"
                                             src="assets/images/rarebreed_logo.png"></a>
    <br/>
    <p class="style-center-align">Rarebreed is a multi-site owner, operator, and business partner to veterinarians in
      the United States.</p>
    <hr/>
    <p class="style-center-align">Please sign in here to continue to application suite.</p>
    <br/>
  </div>
  <div class="col-lg-6 style-center-align">
    <form class="form-group">
      <div class="alert alert-warning" [hidden]='!errorMessage'>{{errorMessage}}</div>
      <button (click)="login()" [disabled]="isLoading" class="btn btn-primary style-center-align btn-spinner"
              [class.transparent-button]="isLoading">
        Sign in with Office 365
        <mat-spinner *ngIf="isLoading" class="spinner" color="accent" diameter="20"></mat-spinner>
      </button>
      <br/><br/>
      <p>Copyright &copy; 2020-{{currentDate | date:'yyyy' }}</p>
    </form>
  </div>
  <div class="row style-filler-row"></div>
</div>
