import {Component, EventEmitter, Input, Output, Pipe, PipeTransform} from '@angular/core';
import {BaseEvent, Employment} from '../../master.entities.models';

@Component({
  selector: 'app-tax-tab',
  templateUrl: './tax-tab.component.html',
  styleUrls: ['./tax-tab.component.css']
})
export class TaxTabComponent {
  @Input() employments: Array<Employment>;
  @Output() taxTabEventEmitter = new EventEmitter<BaseEvent>();

  onTaxEvent($event: BaseEvent) {
    this.taxTabEventEmitter.emit($event);
  }
}

@Pipe({
  name: 'regularEmployments'
})
export class RegularEmploymentsPipe implements PipeTransform {
  transform(items: any[]): any[] {
    return items.filter(item => item.origins?.filter(origin => origin.originType.code === 'PAYLOCITY')[0]?.source === 'REGULAR');
  }
}
