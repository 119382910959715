<div class="row">
  <div class="col col-lg-7">
    <div class="container">
      <h3 class="card-subtitle mb-2 text-muted">State Tax @ {{ employment.location?.name }}</h3>
      <hr style="margin: 0px !important;"/>
      <br/>
    </div>
    <div class="container">
      <div *ngIf="employment">
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>State Tax Code</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="employment.primaryStateTax.taxCode" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let state of states | async" [value]="state">{{state.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>Tax Form</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="employment.primaryStateTax.form" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let taxForm of taxForms | async" [value]="taxForm">{{taxForm.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>W4 Form Year</mat-label>
              <input [disabled]="true" matInput type="number" [(ngModel)]="employment.primaryStateTax.formYear">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>Filing Status</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="employment.primaryStateTax.filingStatus" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let stateTaxFilingStatus of stateTaxFilingStatuses | async"
                            [value]="stateTaxFilingStatus">{{stateTaxFilingStatus.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Exemption 1</mat-label>
              <input [disabled]="true" matInput type="number" [(ngModel)]="employment.primaryStateTax.exemptions">
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Exemption 2</mat-label>
              <input [disabled]="true" matInput type="number" [(ngModel)]="employment.primaryStateTax.exemptions2">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Percentage</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="employment.primaryStateTax.percentage">
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>Amount Type</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="employment.primaryStateTax.amountType" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let stateTaxAmountType of stateTaxAmountTypes | async"
                            [value]="stateTaxAmountType">{{stateTaxAmountType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="employment.primaryStateTax.amount">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Dependents Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="employment.primaryStateTax.dependentsAmount">
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Other Income</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="employment.primaryStateTax.otherIncomeAmount">
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Deduction Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="employment.primaryStateTax.deductionAmount">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>Higher Rate</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="employment.primaryStateTax.higherRate">
                <mat-option [value]=""></mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Special Check Calculation</mat-label>
              <input [disabled]="true" matInput type="text" [(ngModel)]="employment.primaryStateTax.taxSpecialCheckCalc">
            </mat-form-field>
          </div>
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>SUI State</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="employment.primaryStateTax.suiState" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let state of states | async"
                            [value]="state">{{state.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col col-lg-4 offset-lg-1 padding-top-15" *ngIf="employment" [hidden]="!authService.isAdmin()">
    <app-employment-sync-widget [employmentId]="employment.id"></app-employment-sync-widget>
  </div>
</div>



