<div class="container">
  <app-federal-tax [employments]="employments" (federalTaxEventEmitter)="onTaxEvent($event)"></app-federal-tax>
  <br/><br/>
  <div class="row">
    <div *ngFor="let employment of employments | regularEmployments; let i=index">
      <app-state-tax [employment]="employment" (stateTaxEventEmitter)="onTaxEvent($event)"></app-state-tax>
      <br/>
    </div>
  </div>
</div>
