import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


interface VersionInfo {
  commit: string,
  buildNo: string,
  branch: string,
}

interface BuildInfo {
  build_number: string,
}

interface CommitInfo {
  id: string,
}

interface GitInfo {
  branch: string,
  commit: CommitInfo,
}

interface ActuatorInfo {
  build: BuildInfo,
  git: GitInfo,
}

// tslint:disable-next-line:no-empty-interface
export interface DialogData {
  employeeUiInfo: VersionInfo,
  masterAppInfo: ActuatorInfo
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'version-dialog',
  templateUrl: 'version-dialog.html',
  styleUrls: ['version-dialog.css'],
})
// tslint:disable-next-line:component-class-suffix
export class VersionDialog {
  constructor(
    public dialogRef: MatDialogRef<VersionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
