import {Observable} from 'rxjs';

export interface EmployeeAttribute {
  id: number,
  code: string,
  name: string,
  translatable: boolean
}

export interface EmployeeDiscrepancy {
  id: number,
  employeeAttribute: EmployeeAttribute,
  attributeValue: string,
  location?: Location,
  attributePaylocityDisplayValue?: string,
  attributeBambooDisplayValue?: string,
  employeeDiscrepancyOrigins: Array<EmployeeDiscrepancyOrigin>
}

export interface EmployeeDiscrepancyOrigin {
  id: number,
  originalAttributeValue: string,
  originalAttributeValueTranslated: string,
  originalAttributeValueTranslatedId: number,
  originType: OriginType
}

export interface EmployeeDetails {
  gender?: Gender;
  maritalStatus?: MaritalStatus;
  ethnicity?: Ethnicity;
  veteranDescription?: string;
  smoker?: boolean;
  disabilityDescription?: string;

  homeEmail?: string;
  homePhone?: string;
  mobilePhone?: string;
  address?: {
    county?: string;
    state?: any;
    country?: any;

    address1?: string,
    address2?: string,
    city?: string,
    zip?: string,
  },
  birthDate?: Date,
  deleted?: boolean,
  firstName?: string,
  id?: number,
  lastName?: string,
  middleName?: string,
  preferredName?: string,
  ssn?: string,
  jobTitle?: JobTitle,
  hrLink?: {
    name: string,
    value: string
  }
}

export interface Employment {
  id: number,
  employee: BaseMasterEntity,
  department?: Department,
  effectiveOn?: Date,
  hiredOn?: Date,
  terminatedOn?: Date,
  federalTax?: FederalTax,
  jobTitle?: JobTitle,
  location?: Location,
  position?: BaseMasterEntity,
  primaryStateTax?: StateTax,
  rarebreedEmployeeId: string,
  workAddress?: {
    workEmail?: string,
    workPhone?: string,
    workPhoneExtension?: string,

    county?: string;
    state?: any;
    country?: any;

    address1?: string,
    address2?: string,
    city?: string,
    zip?: string,
  },
  employeeStatus?: BaseMasterEntity,
  eligibleForRehire?: boolean,
  statusChangeReason?: string,
  employeeType?: BaseMasterEntity,
  benefitClass?: string,
  benefitClassEffectiveDate?: Date,
  payRate?: {
    payRateEffectiveDate?: Date,
    payRate?: number,
    ratePer?: string,
    payFrequency?: PayFrequency,
    payRateChangeReason?: string,
    paySchedule?: string,
    payType?: PayType,
    workersComp?: string
  }
  manager?: EmployeeRef,
  prosalInfo?: {
    effectiveDate?: Date,
    practiceType?: string,
    contractType?: string,
    servicePercent?: string,
    productPercent?: string,
    diCommission?: string,
    excludeSalary?: boolean
  },
  excludedAaahaAccounts?: Array<AahaAccount>
}

export interface EmployeeRef {
  id: number,
  firstName: string,
  lastName: string
}

export interface YesNo {
  key: string,
  name: string,
  bool: boolean
}

export interface FederalTax {
  amount?: number,
  amountType?: FederalAmountType,
  deductionAmount?: number,
  dependentsAmount?: number,
  exemptions?: number,
  filingStatus?: FederalFilingStatus,
  form?: TaxForm,
  formYear?: number,
  higherRate?: boolean,
  otherIncomeAmount?: number,
  percentage?: number,
}

export interface StateTax {
  amount?: number,
  amountType?: StateAmountType,
  deductionAmount?: number,
  dependentsAmount?: number,
  exemptions?: number,
  exemptions2?: number,
  filingStatus?: StateFilingStatus,
  form?: TaxForm,
  formYear?: number,
  higherRate?: boolean,
  otherIncomeAmount?: number,
  percentage?: number,
  taxCode?: string,
  taxSpecialCheckCalc?: string,
  suiState?: string,
}

export interface BaseMasterEntity {
  id: number,
  name: string,
}

export interface Country extends BaseMasterEntity {
}

export interface State extends BaseMasterEntity {
}

export interface BenefitClass extends BaseMasterEntity {
}

export interface OvertimeStatus extends BaseMasterEntity {
}

export interface PayType extends BaseMasterEntity {
}

export interface PayFrequency extends BaseMasterEntity {
}

export interface JobTitle extends BaseMasterEntity {
}


export interface Department extends BaseMasterEntity {
}

export interface Gender extends BaseMasterEntity {
}

export interface Ethnicity extends BaseMasterEntity {
}

export interface MaritalStatus extends BaseMasterEntity {

}

export interface EmployeeStatus extends BaseMasterEntity {
}

export interface EmployeeType extends BaseMasterEntity {
}

export interface TaxForm extends BaseMasterEntity {

}

export interface StateAmountType extends BaseMasterEntity {

}

export interface FederalAmountType extends BaseMasterEntity {

}

export interface StateFilingStatus extends BaseMasterEntity {

}

export interface FederalFilingStatus extends BaseMasterEntity {

}

export interface Location extends BaseMasterEntity {
}

export interface Position extends BaseMasterEntity {

}

export interface OriginType extends BaseMasterEntity {
  code: string
}

export interface AahaAccount extends BaseMasterEntity {
  code: string,
  revenueCategory: string,
  checked?: boolean
}

export interface MasterEntities {
  locations: Observable<Array<Location>>,
  departments: Observable<Array<Department>>,
  positions: Observable<Array<Position>>,
  jobTitles: Observable<Array<JobTitle>>,
  employeeStatuses: Observable<Array<EmployeeStatus>>,
  employeeTypes: Observable<Array<EmployeeType>>,
  benefitClasses: Observable<BenefitClass[]>,
  payTypes: Observable<PayType[]>,
  payFrequencies: Observable<PayFrequency[]>,
  overtimeStatuses: Observable<OvertimeStatus[]>,
  aahaAccounts: Observable<AahaAccount[]>
}

export enum Action {
  CREATE,
  UPDATE
}

export interface BaseEvent {
  action: Action
}

export interface EmployeeEvent extends BaseEvent {
  id?: number,
  name?: string
}

export interface EmploymentEvent extends BaseEvent {
  employment: Employment
}
