<div class="container" *ngIf="personalInfo">
  <div class="row">
    <div class="col col-lg-7">
      <div class="row style-row-gap-10">

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>First Name</mat-label>
            <input [disabled]="true" matInput required [(ngModel)]="personalInfo.firstName">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Last Name</mat-label>
            <input [disabled]="true" matInput required [(ngModel)]="personalInfo.lastName">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Middle Name</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.middleName">
          </mat-form-field>
        </div>
        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Preferred Name</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.preferredName">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field style="width: 100%">
            <mat-label>Date of Birth</mat-label>
            <input [disabled]="true" matInput [formControl]="birthDate" [matDatepicker]="dateOfBirth">
            <mat-datepicker-toggle *ngIf="birthDate.value != null" matSuffix (click)="birthDate.setValue(null)">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field style="width: 100%">
            <mat-label>Gender</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="personalInfo.gender" [compareWith]="compareById">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let gender of genders | async" [value]="gender">{{gender.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col col-lg-12">
          <mat-form-field style="width: 100%">
            <mat-label>SSN</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.ssn" placeholder="SSN"
                   [type]="ssnHidden ? 'password' : 'text'" autocomplete="new-password">
            <mat-icon matSuffix
                      (click)="ssnHidden = !ssnHidden">{{ssnHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field style="width: 100%">
            <mat-label>Marital Status</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="personalInfo.maritalStatus" [compareWith]="compareById">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let maritalStatus of maritalStatuses | async"
                          [value]="maritalStatus">{{maritalStatus.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field style="width: 100%">
            <mat-label>Ethnicity</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="personalInfo.ethnicity" [compareWith]="compareById">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let ethnicity of ethnicities | async"
                          [value]="ethnicity">{{ethnicity.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Veteran</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.veteranDescription">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Disability</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.disabilityDescription">
          </mat-form-field>
        </div>

        <div class="col col-lg-12">
          <mat-checkbox [disabled]="true" [(ngModel)]="personalInfo.smoker">Smoker
          </mat-checkbox>
        </div>
      </div>

      <br/><br/>
      <h2 class="card-subtitle mb-2 text-muted">Contact Details</h2>
      <hr style="margin: 0px !important;"/>

      <div class="row">
        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Address 1</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.address.address1">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Address 2</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.address.address2">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>City</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.address.city">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>County</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.address.county">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field style="width: 100%">
            <mat-label>State</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="personalInfo.address.state" [compareWith]="compareById">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let state of states | async" [value]="state">{{state.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field style="width: 100%">
            <mat-label>Country</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="personalInfo.address.country" [compareWith]="compareById">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let country of countries | async" [value]="country">{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Zip</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.address.zip">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Mobile Phone</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.mobilePhone">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Home Phone</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.homePhone">
          </mat-form-field>
        </div>

        <div class="col col-lg-6">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Personal Email</mat-label>
            <input [disabled]="true" matInput [(ngModel)]="personalInfo.homeEmail">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col col-lg-4 offset-lg-1 padding-top-15" [hidden]="!isAdmin()">
      <app-employee-sync-widget [employeeId]="personalInfo.id" [virtual]="false"></app-employee-sync-widget>
    </div>
  </div>
</div>
