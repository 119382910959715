import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  Country,
  EmployeeDetails,
  EmployeeEvent,
  Ethnicity,
  Gender,
  MaritalStatus,
  State
} from '../../master.entities.models';
import {SpinnerService} from '../../../spinner/spinner.service';
import {MasterEmployeeService} from '../../master.employee.service';
import {MasterEntitiesService} from '../../master.entities.service';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../login/auth.service';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'personal-tab',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalTabComponent implements OnInit, OnChanges {

  constructor(private spinnerService: SpinnerService,
              private masterEmployeeService: MasterEmployeeService,
              private masterEntitiesService: MasterEntitiesService,
              private toastrService: ToastrService,
              public authService: AuthenticationService) {
  }

  ssnHidden = true;
  genders: Observable<Array<Gender>>;
  maritalStatuses: Observable<Array<MaritalStatus>>;
  ethnicities: Observable<Array<Ethnicity>>;
  states: Observable<Array<State>>;
  countries: Observable<Array<Country>>;


  @Input() personalInfo: EmployeeDetails;
  // @Output()
  @Output() personalInfoUpdate = new EventEmitter<EmployeeEvent>();
  birthDate = new FormControl();

  ngOnInit() {
    this.genders = this.masterEntitiesService.getGenders();
    this.maritalStatuses = this.masterEntitiesService.getMaritalStatuses();
    this.ethnicities = this.masterEntitiesService.getEthnicities();
    this.countries = this.masterEntitiesService.getCountries();
    this.states = this.masterEntitiesService.getStates();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.personalInfo = changes.personalInfo.currentValue || {} as EmployeeDetails;
    this.birthDate.setValue(this.personalInfo.birthDate);
  }

  public onDropDownModelChange(prop: string, $event) {
    setInner(prop.split('.').reverse(), this.personalInfo, $event ? {id: $event} : null);
    // tslint:disable-next-line:no-shadowed-variable
    function setInner(prop: string[], obj: any, value: any) {
      if (prop.length === 1) {
        obj[prop[0]] = value;
      } else {
        const innerObj = obj[prop.pop()]
        setInner(prop, innerObj, value);
      }
    }
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  compareById(fist, second) {
    return fist && second && fist.id === second.id;
  }
}
