import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTable} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Change} from './change-history.model';
import {MasterChangeHistoryService} from '../../master.change-history.service';
import {DetailsDataService} from '../details.data.service';
import {ViewDiffDialog} from './modals/view/view-diff-dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'change-history-tab',
  templateUrl: './change-history.component.html',
  styleUrls: ['./change-history.component.css']
})
export class ChangeHistoryComponent implements OnDestroy {
  @Input() virtual: boolean;
  displayedColumns: string[] = ['id', 'author', 'typeName', 'changeType', 'commitDate', 'star'];
  data: Change[] = [];
  subscription;
  isLoadingResults = true;
  resultsLength = 0;
  pageSize = 25;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public changeHistoryService: MasterChangeHistoryService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private detailsDataService: DetailsDataService) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.subscription = this.detailsDataService.currentMessage.subscribe((msg: any) => {
      if (msg && msg.id && msg.textLabel === 'Change History') {
        this.subscribe(msg.id);
      }
    });
  }

  subscribe(id) {
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          // tslint:disable-next-line:max-line-length
          return this.changeHistoryService.findAll(id, this.virtual, this.paginator.pageIndex, this.paginator.pageSize)
            .pipe(catchError(() => observableOf(null)));
        }),
        map(result => {
          this.isLoadingResults = false;
          // @ts-ignore
          this.resultsLength = result?.totalElements;
          // @ts-ignore
          return result?.content;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  openViewDialog(change): void {
    const dialogRef = this.dialog.open(ViewDiffDialog, {
      width: '600px',
      data: change,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
