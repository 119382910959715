import {Component} from '@angular/core';
import {AuthenticationService} from './auth.service';
import {GlobalConstants} from '../global-constants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  errorMessage: string;
  isLoading: boolean;
  isLoggedIn = false;
  currentDate = new Date();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {
  }

  login() {
    this.isLoading = true;
    this.errorMessage = '';
    if (!this.isLoggedIn) {
      this.authenticationService.login(
        resp => {
          this.errorMessage = '';
          const userRole = sessionStorage.getItem(GlobalConstants.AUTH_USER_ROLE);
          if (userRole === 'ADMIN') {
            this.isLoggedIn = true;
            this.router.navigate(['/employees/list']).then(() => console.log('Routed to employees page'));
          } else {
            this.isLoading = false;
            this.isLoggedIn = false;
            this.errorMessage = 'Users only with ADMIN credentials are allowed to be logged in.';
          }
        },
        error => {
          this.errorMessage = error.message;
          this.isLoading = false;
          this.isLoggedIn = false;
        });
    }
  }
}
