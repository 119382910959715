import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmployeeListFiltersService} from '../employee/list/employee-list.filters.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {SearchFilters} from '../employee/list/employee-list.model';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {VirtualEmployee} from './virtual-employee.models';
import {VirtualEmployeeService} from './virtual-employee.service';
import {CreateVirtualEmployeeDialog} from './modals/create/create-virtual-employee-dialog';
import {DeleteVirtualEmployeeDialog} from './modals/delete/delete-virtual-employee-dialog';

@Component({
  selector: 'app-virtual-employee',
  templateUrl: './virtual-employee.component.html',
  styleUrls: ['./virtual-employee.component.css']
})
export class VirtualEmployeeComponent implements AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filters: SearchFilters;
  displayedColumns: string[] = ['rarebreedId', 'id', 'name', 'star'];
  data: VirtualEmployee[] = [];
  dataSource: MatTableDataSource<VirtualEmployee>;
  isLoadingResults = true;
  resultsLength = 0;
  pageSize = 25;
  readonly LOCAL_STORAGE_FILTERS_SUFFIX = 'virtual-employees';

  constructor(public route: ActivatedRoute,
              public router: Router,
              public virtualEmployeeService: VirtualEmployeeService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private filtersService: EmployeeListFiltersService) {
    this.initFilters();
  }

  private initFilters() {
    this.filters = this.filtersService.getFilters(this.LOCAL_STORAGE_FILTERS_SUFFIX);
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          // tslint:disable-next-line:max-line-length
          return this.virtualEmployeeService.findAll(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.filtersService.buildSearchParam(this.filters))
            .pipe(catchError(() => observableOf([])));
        }),
        map(result => {
          this.isLoadingResults = false;
          // @ts-ignore
          this.resultsLength = result?.totalElements;
          // @ts-ignore
          return result?.content;
        }),
      )
      .subscribe(data => (this.asDataSource(data)));
  }

  asDataSource(data: VirtualEmployee[]) {
    this.data.splice(0, this.data.length);
    Array.prototype.push.apply(this.data, data);
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
  }

  updateFilter() {
    this.filtersService.saveFilters(this.filters, this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.reset();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  resetGrid() {
    this.filtersService.resetFilters(this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.initFilters();
    this.reset();
  }

  calculateActiveFiltersCount() {
    let result = 0;
    for (const [key, value] of Object.entries(this.filters)) {
      if (value.value) {
        result++;
      }
    }
    return result;
  }

  openCreateDialog(virtualEmployee = {} as VirtualEmployee): void {
    const data = Object.assign({}, virtualEmployee);
    const dialogRef = this.dialog.open(CreateVirtualEmployeeDialog, {
      width: '600px',
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (virtualEmployee.id) {
          const toUpdate = this.data.filter(item => item.id === result.id)[0];
          Object.assign(toUpdate, result);
        } else {
          // tslint:disable-next-line:no-shadowed-variable
          this.data.unshift(result);
          this.resultsLength += 1;
          this.table.renderRows();
          this.dataSource._updateChangeSubscription();
        }
      }
    });
  }

  openDeleteDialog(entity): void {
    const dialogRef = this.dialog.open(DeleteVirtualEmployeeDialog, {
      width: '550px',
      data: {id: entity.id, name: entity.name},
    });

    // tslint:disable-next-line:ter-arrow-parens
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        entity.loading = true;
        this.virtualEmployeeService.delete(entity.id)
          .subscribe((res: { id: any }) => {
            const index = this.dataSource.data.findIndex(item => item.id === entity.id);
            this.dataSource.data.splice(index, 1);
            this.resultsLength -= 1;
            this.table.renderRows();
            this.dataSource._updateChangeSubscription();
            this._snackBar.open('Hospital Rev account \'' + entity.name + '\' was successfully deleted!', 'Close', {
              duration: 5000,
            });
          });
      }
    });
  }

  editVirtualEmployee(event, selectedElement: any) {
    if (event.srcElement.localName !== 'editable' &&
      event.srcElement.localName !== 'mat-icon' &&
      event.srcElement.localName !== 'input') {
      this.router.navigate(['employees/virtual-employee', selectedElement.id, selectedElement]);
    }
  }
}
