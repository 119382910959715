<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a href="#" class="navbar-brand text-light">
      <img height="45" alt="Rarebreed Veterinary Partners" src="assets/images/rarebreed_puli_logo.png"/>&nbsp;Employee Service
    </a>
    <span class="spacer"></span>
    <span class="text-light">&nbsp;{{userFullName}}&nbsp;({{userrole}})&nbsp;</span>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar-row>

</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="isLoggedIn && userrole == 'ADMIN'" routerLink='/#/listemployee'>
    <mat-icon aria-hidden="false">group</mat-icon>
    All Employees
  </button>
  <button mat-menu-item *ngIf="isLoggedIn" (click)="openVersionDialog()">
    <mat-icon aria-hidden="false">info</mat-icon>
    Version Info
  </button>
  <button mat-menu-item *ngIf="isLoggedIn" routerLink='/login' (click)=handleLogout()>
    <mat-icon aria-hidden="false">logout</mat-icon>
    Logout
  </button>
</mat-menu>
