<ng-container *ngIf="(rows | async)?.length > 0 && (rows | async) as rows$">
  <div style="width: 25rem">
    <div class="card-body">
      <div *ngFor="let row of rows$;let isFirst = first" class="pane py-2 px-3">
        <hr *ngIf="isFirst" class="faded" style="margin-bottom: 10px; margin-top: 0px;"/>
        <div>
          <h5 class="card-title"><span class="bi bi-circle-fill" style="font-size: 0.6rem;"
                                       [ngClass]="row.color"></span>
            {{row.item.externalSystem.name}}

            <span *ngIf="(row.item.externalSystem.code === 'NETSUITE' || row.item.externalSystem.code === 'VETSOURCE') && row.item.syncStatus !== 'NEVER'">
              <a *ngIf="row.item.externalSystem.code === 'NETSUITE'" href="javascript:void(0)" (click)="openMatchDialog(row.item, true)" style="float: right">
                Remap to another
              </a><br/>
              <a *ngIf="row.item.externalSystem.code === 'NETSUITE' || row.item.externalSystem.code === 'VETSOURCE'" href="javascript:void(0)" (click)="unmap(row.item)" style="float: right">
                Unmap
              </a>
            </span>
          </h5>
          <p>
            <a [href]="row.item.link" target="_blank" [hidden]="!row.item.link">{{row.item.title}}
              <i class="bi bi-box-arrow-up-right"></i>
            </a>
            <span [hidden]="row.item.link" style="color: dimgray; text-decoration: underline">{{row.item.title}}</span>
          </p>
          <p *ngIf="row.item.syncStatus === 'DONE'">Successful update {{messageFrom(row)}}</p>
          <p *ngIf="row.item.syncStatus === 'FAILED'">There were <a href="javascript:void(0)"
                                                                    (click)="openViewDialog(row.item)">errors</a> during
            update {{messageFrom(row)}}</p>
          <p *ngIf="row.item.syncStatus === 'PARTIALLY_DONE'">There were <a href="javascript:void(0)"
                                                                            (click)="openViewDialog(row.item)">warnings</a>
            during update {{messageFrom(row)}}</p>
          <p *ngIf="row.item.syncStatus === 'NEVER'">
            <span *ngIf="row.item.externalSystem.code !== 'NETSUITE'">No synchronization has been performed at the moment.</span>
            <span *ngIf="row.item.externalSystem.code === 'NETSUITE'">This record is not associated with Netsuite employee</span>
            <a *ngIf="row.item.externalSystem.code !== 'NETSUITE'" href="javascript:void(0)"
               (click)="openViewDialog(row.item)">See details</a>
          </p>

          <p *ngIf="row.item.syncStatus === 'NEVER' && row.item.externalSystem.code === 'NETSUITE'">
            <a href="javascript:void(0)" (click)="openMatchDialog(row.item)">
              Match to an existing employee from NetSuite
            </a><br/>
            <a href="javascript:void(0)" (click)="createNew(row.item)">
              Create a new record in NetSuite for this employee
            </a>
          </p>

          <p class="text-muted" *ngIf="row.triggeredAt">
            <small>Triggered {{row.triggeredAt | date:'medium'}} by {{row.triggeredBy}}</small>
          </p>
        </div>
        <hr class="faded" style="margin: 0px; "/>
      </div>
    </div>
  </div>
</ng-container>

