import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { AuthenticationService } from './login/auth.service';
import { GlobalConstants } from './global-constants';
import { Router, ActivatedRoute } from '@angular/router';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private router: Router,
                private toastrService: ToastrService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authenticationService.isUserLoggedIn() && req.url.indexOf('basicauth') === -1) {
            const authReq = req.clone({
                headers: new HttpHeaders({
                    // 'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem(GlobalConstants.AUTH_SESSION_TOKEN)
                })
            });
            return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x)));
        } else {
            if (this.router.url !== '/' && this.router.url.indexOf('login') === -1) {
              this.router.navigate(['/login']); // This redirects any page to login page if user is not authenticated.
            }
            return next.handle(req);
        }
    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
      if (err.status === 401) {
        // refresh token??
        if (this.toastrService.currentlyActive === 0) {
          this.toastrService.warning('Session is expired');
          this.authenticationService.logout();
        }
        return of(err.message);
      } else if(err.status === 403) {
        this.toastrService.error('You\'re not allowed to access this resource.', 'Insufficient permissions')
      }
      return throwError(err);
    }
}
