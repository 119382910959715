import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {VirtualEmployeeService} from '../virtual-employee.service';
import {VirtualEmployee} from '../virtual-employee.models';
import {SpinnerService} from '../../spinner/spinner.service';
import {Action, EmployeeEvent} from '../../employee/master.entities.models';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-virtual-employee-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './virtual-employee-form.component.html',
  styleUrls: ['./virtual-employee-form.component.css']
})
export class VirtualEmployeeFormComponent implements OnChanges {

  constructor(private spinnerService: SpinnerService,
              private _snackBar: MatSnackBar,
              public virtualEmployeeService: VirtualEmployeeService) {
  }

  @Input() virtualEmployee: VirtualEmployee;
  @Output() virtualEmployeeUpdate = new EventEmitter<EmployeeEvent>();

  ngOnChanges(changes: SimpleChanges) {
    this.virtualEmployee = changes.virtualEmployee.currentValue || {} as VirtualEmployee;
  }

  save() {
    this.spinnerService.showSpinner();
    if (!this.virtualEmployee.id) {
      this.virtualEmployeeService.create(this.virtualEmployee)
        .pipe(
          finalize(() => {
            this.spinnerService.hideSpinner();
          })
        )
        .subscribe(
          response => {
            this._snackBar.open('Hospital Rev account \'' + this.virtualEmployee.name + '\' was successfully created!', 'Close', {
              duration: 5000,
            });
            this.virtualEmployeeUpdate.emit({
              action: Action.CREATE,
              id: response.id,
              name: `${this.virtualEmployee.name}`
            });
          },
          response => {
            console.log(`Cannot create new employee : \n${response.error.status} :  ${response.error.error}`);
          });
    } else {
      this.virtualEmployeeService.update(this.virtualEmployee.id, this.virtualEmployee)
        .pipe(
          finalize(() => {
            this.spinnerService.hideSpinner();
          })
        ).subscribe(
        r => {
          this._snackBar.open('Hospital Rev account \'' + this.virtualEmployee.name + '\' was successfully updated!', 'Close', {
            duration: 5000,
          });
          this.virtualEmployeeUpdate.emit({action: Action.UPDATE, id: this.virtualEmployee.id});
        },
        response => {
          console.log(`Cannot create new employee : \n${response.error.status} :  ${response.error.error}`);
        });
    }
  }
}
