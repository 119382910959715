<div class="container">
  <div class="row style-row-gap-10">
    <div [hidden]="!newEmploymentFormVisible">
      <app-employment [employee]="employee"
                      [employment]="newEmployment"
                      [masterEntities]="masterEntities"
                      (employmentEventEmitter)="onEmploymentEvent($event)"></app-employment>
      <br/>
    </div>
    <p *ngIf="employments.length == 0 && !isNewEmploymentFormVisible()">No employments found</p>
    <div *ngFor="let employmentInfo of employments">
      <app-employment [employee]="employee"
                      [employment]="employmentInfo"
                      [masterEntities]="masterEntities"
                      (employmentEventEmitter)="onEmploymentEvent($event)"></app-employment>
      <br/>
    </div>
  </div>
</div>

