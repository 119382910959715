import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ExternalSystemSyncStatus} from '../../synchronization.model';


@Component({
  templateUrl: 'view-sync-details.component.html'
})
export class ViewSyncDetailsComponent {

  constructor(
    public dialogRef: MatDialogRef<ViewSyncDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public syncDetails: ExternalSystemSyncStatus) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
