import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {map} from 'rxjs/operators';
import {SynchronizationDirective} from './synchronization.directive';
import {Employment} from '../../master.entities.models';


@Component({
  selector: 'app-federal-tax-sync-widget',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.css']
})
export class FederalTaxSynchronizationComponent extends SynchronizationDirective implements OnChanges {
  @Input() employments: Array<Employment>;

  ngOnChanges(changes: SimpleChanges): void {
    this.employments = changes.employments.currentValue as Array<Employment>;
    if (this.employments) {
      this.init()
    }
  }

  private init() {
    this.employments.forEach(e => {
      this.synchronizationService.getEmploymentOrigins(e.id)
        .pipe(map(items => items.map(item => this.convert(item)))).subscribe(t => {
          if(this.rows.value){
            this.rows.next([...Object.values(this.rows.value),...t]);
          }else{
            this.rows.next(t);
          }
      }, error => {
        console.log(error);
      });
    });
  }
}
