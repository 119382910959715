<div class="row">
  <div class="col col-lg-7">
    <div class="container">
      <br/>
      <h3 class="card-subtitle mb-2 text-muted">Federal Tax</h3>
      <hr style="margin: 0px !important;"/>
      <br/>
    </div>
    <div class="container">
      <div *ngIf="federalTaxInfo">
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%">
              <mat-label>Tax Form</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="federalTaxInfo.form" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let taxForm of taxForms | async" [value]="taxForm">{{taxForm.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col col-lg-8">
            <mat-form-field style="width: 100%">
              <mat-label>Filing Status</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="federalTaxInfo.filingStatus" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let federalTaxFilingStatus of federalTaxFilingStatuses | async"
                            [value]="federalTaxFilingStatus">{{federalTaxFilingStatus.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>W4 Form Year</mat-label>
              <input [disabled]="true" matInput type="number" [(ngModel)]="federalTaxInfo.formYear">
            </mat-form-field>
          </div>

          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Exemptions</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="federalTaxInfo.exemptions">
            </mat-form-field>
          </div>

          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Percentage</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="federalTaxInfo.percentage">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>Amount Type</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="federalTaxInfo.amountType" [compareWith]="compareFn">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let federalTaxAmountType of federalTaxAmountTypes | async"
                            [value]="federalTaxAmountType">{{federalTaxAmountType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="federalTaxInfo.amount">
            </mat-form-field>
          </div>

          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Dependents Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="federalTaxInfo.dependentsAmount">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Other Income Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="federalTaxInfo.otherIncomeAmount">
            </mat-form-field>
          </div>

          <div class="col col-lg-4">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>Deduction Amount</mat-label>
              <input [disabled]="true" matInput type="number" step=".01" [(ngModel)]="federalTaxInfo.deductionAmount">
            </mat-form-field>
          </div>

          <div class="col col-lg-4">
            <mat-form-field style="width: 100%; padding-top: 12px">
              <mat-label>Higher Rate</mat-label>
              <mat-select [disabled]="true"[(ngModel)]="federalTaxInfo.higherRate">
                <mat-option [value]=""></mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col col-lg-4 offset-lg-1 padding-top-15" *ngIf="employments" [hidden]="!authService.isAdmin()">
    <app-federal-tax-sync-widget [employments]="employments | regularEmployments"></app-federal-tax-sync-widget>
  </div>
</div>
